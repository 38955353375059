<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form_name"
              outlined
              :label="$t('pages_specific.contact.form_labels.name')"
              color="dark"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="form_mail"
              outlined
              :label="$t('pages_specific.contact.form_labels.mail')"
              color="dark"
              :rules="emailRules"
              required
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="form_company"
              outlined
              :label="$t('pages_specific.contact.form_labels.company')"
              color="dark"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="form_phone"
              outlined
              :label="$t('pages_specific.contact.form_labels.phone')"
              color="dark"
              :rules="phoneRules"
              required
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="form_message"
              :label="$t('pages_specific.contact.form_labels.message')"
              outlined
              :placeholder="
                $t('pages_specific.contact.form_labels.message_placeholder')
              "
              color="dark"
              :rules="messageRules"
              required
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" md="12">
            <div v-if="show_sum_of_two_numbers_captcha">
              <div class="error--text font-weight-medium">
                {{
                  $t(
                    "pages_specific.contact.form_validation_msg.verify_not_robot"
                  )
                }}
              </div>
              <div class="d-flex align-baseline">
                <div class="mr-2">
                  {{ captcha_num_1 }} + {{ captcha_num_2 }} = ?
                </div>
                <div>
                  <v-text-field
                    ref="user_input_captcha_field"
                    placeholder="please enter the sum"
                    v-model="user_input_captcha"
                    :rules="userCaptchaRules"
                    required
                  />
                </div>
              </div>
            </div>

            <v-btn
              width="100%"
              color="primary"
              class="dark--text"
              @click="validateAndSubmit"
              :loading="is_waiting_server_response_flag"
            >
              {{ $t("pages_specific.contact.form_labels.submit") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-spacer />

    <!-- Show response  -->
    <v-card
      v-if="response_is_received_flag"
      outlined
      :class="{
        'border-color-warning': response_is_error_flag,
        'border-color-ok': !response_is_error_flag,
      }"
      class="pa-3 my-5"
    >
      {{ response_message }}

      <v-icon class="float-right" @click="reinitializeResponseStatus()">
        mdi-close
      </v-icon>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { email_regex } from '../../utils/RegexUtils';
import { sendContactEmail, verifyCaptcha } from "../../utils/ServerCallsUtils";
import { SERVER_RESPONSES_ENUM } from "../../utils/ServerResponsesUtils";

// A random integer is returned between a min value (included) and a max value (inclusive).
function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
  data() {
    return {
      form_name: "",
      form_mail: "",
      form_company: "",
      form_phone: "",
      form_message: "",
      contact_email_response: SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET,
      response_message: "",

      show_sum_of_two_numbers_captcha: false,
      captcha_num_1: 0,
      captcha_num_2: 0,
      captcha_total: 0,
      user_input_captcha: "",

      captcha_token: null,
    };
  },

  created() {
    this.reinitCaptcha();

    if (null != this.user_profile && null != this.user_profile.json_body) {
      this.form_mail = this.user_profile.json_body.username;
      this.form_phone = this.user_profile.json_body.phone_number;
    }
  },

  mounted() {
    this.reinitCaptcha();
  },

  computed: {
    ...mapState(["user_profile"]),

    emailRules() {
      return [
        (v) => !!v || this.$t("form_validation.your_mail_required"),
        (v) => email_regex.test(v) || this.$t("form_validation.mail_valid"),
      ];
    },

    messageRules() {
      return [
        (value) =>
          !!value ||
          this.$t(
            "pages_specific.contact.form_validation_msg.message_required"
          ),
        (value) =>
          (value && value.length >= 3) ||
          this.$t("pages_specific.contact.form_validation_msg.message_valid"),
      ];
    },

    phoneRules() {
      var regex_at_least_10_digits = /^!*(\d!*){10,}$/;
      return [
        (v) =>
          !!v ||
          this.$t("pages_specific.contact.form_validation_msg.phone_required"),
        (v) =>
          // replace(/[^0-9]/ig, "") --> remove all caracters except of digits
          regex_at_least_10_digits.test(v ? v.replace(/[^0-9]/gi, "") : "") ||
          this.$t("pages_specific.contact.form_validation_msg.phone_valid"),
      ];
    },

    response_is_received_flag() {
      return (
        this.contact_email_response != SERVER_RESPONSES_ENUM.WAITING_RESPONSE &&
        this.contact_email_response !=
          SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET
      );
    },

    response_is_error_flag() {
      return (
        this.response_is_received_flag &&
        this.contact_email_response != SERVER_RESPONSES_ENUM.RESPONSE_OK
      );
    },

    is_waiting_server_response_flag() {
      return (
        SERVER_RESPONSES_ENUM.WAITING_RESPONSE == this.contact_email_response
      );
    },

    userCaptchaRules() {
      return [(value) => value == this.captcha_total];
    },
  },

  methods: {
    reinitCaptcha() {
      this.captcha_token = null;
      this.reinitSumOfTwoNumbersCaptcha();
      this.show_sum_of_two_numbers_captcha = false;
    },

    async validateAndSubmit() {
      this.contact_email_response = SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET;

      if (this.$refs.form.validate()) {
        const google_captcha_is_valid = await this.google_recaptcha();

        if (google_captcha_is_valid) {
          this.sendContactEmail();
        } else {
          this.show_sum_of_two_numbers_captcha = true;

          if (null != this.$refs.user_input_captcha_field) {
            var user_captcha_result_is_valid =
              this.$refs.user_input_captcha_field.validate();

            if (user_captcha_result_is_valid) {
              this.sendContactEmail();
              this.reinitSumOfTwoNumbersCaptcha();
            }
          }
        }
      }
    },

    async sendContactEmail() {
      this.contact_email_response = SERVER_RESPONSES_ENUM.WAITING_RESPONSE;

      this.contact_email_response = await sendContactEmail(
        this.form_name,
        this.form_mail,
        this.form_company,
        this.form_phone,
        this.form_message
      );

      this.response_message = this.getMessageFromResponseCode();
    },

    reinitSumOfTwoNumbersCaptcha() {
      this.captcha_num_1 = getRandomIntInclusive(0, 9);
      this.captcha_num_2 = getRandomIntInclusive(0, 9);
      this.captcha_total = this.captcha_num_1 + this.captcha_num_2;
    },

    getMessageFromResponseCode() {
      switch (this.contact_email_response) {
        case SERVER_RESPONSES_ENUM.RESPONSE_OK:
          return this.$t("pages_specific.contact.contact_email_sent");
        case SERVER_RESPONSES_ENUM.ERROR_EMAIL_WRONG_FORMAT:
          return this.$t("global.email_wrong_format");
        default:
          // unknown error with no details provided
          return this.$t("global.unknow_error");
      }
    },

    reinitializeResponseStatus() {
      this.contact_email_response = SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET;
    },

    async google_recaptcha() {
      try {
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "submit".
        if (null == this.captcha_token) {
          this.captcha_token = await this.$recaptcha("submit");
        }

        const captcha_is_valid = await verifyCaptcha(this.captcha_token);
        console.log("Recaptcha is valid: " + captcha_is_valid);
        return captcha_is_valid;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>