<template>
  <v-container class="pb-15">
    <wait-server-progress
      v-if="
        SERVER_RESPONSES_ENUM.WAITING_RESPONSE == user_profile.response_status
      "
    />

    <div
      v-else-if="
        SERVER_RESPONSES_ENUM.RESPONSE_OK == user_profile.response_status
      "
    >
      <user-connected-main-page-container />
    </div>

    <sign-in-or-log-in v-else />
  </v-container>
</template>

<script>
import WaitServerProgress from "../components/app_specific/WaitServerProgress.vue";
import SignInOrLogIn from "../components/app_specific/SignInOrLogIn.vue";
import { mapState } from "vuex";
import UserConnectedMainPageContainer from "../components/app_specific/UserConnectedMainPageContainer.vue";
import { SERVER_RESPONSES_ENUM } from "../utils/ServerResponsesUtils";

export default {
  components: {
    WaitServerProgress,
    SignInOrLogIn,
    UserConnectedMainPageContainer,
  },

  computed: {
    ...mapState(["user_profile"]),

    SERVER_RESPONSES_ENUM() {
      return SERVER_RESPONSES_ENUM;
    },
  },
};
</script>
