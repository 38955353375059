<template>
  <v-footer class="py-2" color="dark" height="auto" v-if="!mobileAppWebView">
    <v-container>
      <v-row>
        <div class="copyright">
          <div>
            © {{ new Date().getFullYear() }} Strataggem.
            {{ $t("global.copyright") }}
          </div>
          <div class="mb-3">
            <router-link style="color: inherit" to="/terms">
              {{ $t("global.terms_of_service") }}</router-link
            >,
            <router-link style="color: inherit" to="/policy">
              {{ $t("global.privacy_policy") }}</router-link
            >
          </div>
        </div>

        <v-spacer />

        <language-changer :footer_version="true" class="pr-5" />
        <base-btn
          color="accent"
          square
          title="Go to top"
          @click="$vuetify.goTo(0)"
        >
          <v-icon class="up">mdi-chevron-up</v-icon>
        </base-btn>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageChanger from "../app_specific/LanguageChanger.vue";
export default {
  components: { LanguageChanger },

  computed: {
    ...mapGetters(["is_carnaval_service"]),

    mobileAppWebView() {
      const mobileAppWebViewByRouteName = "androidWebView" == this.$route.name;
      const mobileAppWebViewByQueryParam =
        null != this.$route.query &&
        null != this.$route.query.androidWebView &&
        "true" == this.$route.query.androidWebView;
      return (
        mobileAppWebViewByRouteName ||
        mobileAppWebViewByQueryParam ||
        this.is_carnaval_service
      );
    },
  },
};
</script>

<style scoped>
.copyright {
  color: #7b796f;
  font-size: small;
  padding: 12px 0 0 12px;
}
.v-btn,
.v-btn.accent {
  color: #15191a;
}
</style>