<template>
  <span>
    <input
      ref="browse_file_input"
      @change="uploadFile"
      type="file"
      :disabled="disabled"
      accept="image/*"
    />
    <v-btn icon v-if="!disabled" @click="resetFile">
      <v-icon dark>mdi-delete</v-icon>
    </v-btn>

    <v-card
      v-if="showFileIsTooLargeMessage"
      outlined
      class="border-color-warning pa-3 mt-3 mb-5"
    >
      {{
        $t("pages_specific.profile_page.file_too_large", {
          max_file_size: MAX_FILE_SIZE_MB,
        })
      }}
    </v-card>
  </span>
</template>

<script>
const MAX_FILE_SIZE_MB = 20;

export default {
  props: {
    disabled: Boolean,
  },

  data() {
    return {
      showFileIsTooLargeMessage: false,
    };
  },

  computed: {
    MAX_FILE_SIZE_MB() {
      return MAX_FILE_SIZE_MB;
    },
  },

  methods: {
    uploadFile(ev) {
      const file = ev.target.files[0];

      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        ev.preventDefault();
        this.showFileIsTooLargeMessage = true;
        return;
      } else {
        this.showFileIsTooLargeMessage = false;
      }

      const reader = new FileReader();
      let image_url_and_file = {};
      image_url_and_file.file = file;

      reader.onload = (e) => {
        image_url_and_file.image_url = e.target.result;
        this.$emit("load", image_url_and_file);
      };
      reader.readAsDataURL(file);
    },

    resetFile() {
      this.$refs["browse_file_input"].value = null;
      this.$emit("load", {});
      this.showFileIsTooLargeMessage = false;
    },
  },
};
</script>
