var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-subheading',[_vm._v(_vm._s(_vm.$t(_vm.path_in_translations + ".title")))]),_c('p',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t(_vm.path_in_translations + ".effective_date"))+" ")]),_c('div',{staticClass:"mt-10"},_vm._l((_vm.$t(_vm.path_in_translations + '.list')),function(chapter,i){return _c('div',{key:'ch_' + i,staticClass:"mt-5"},[_c('p',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" "+_vm._s(i + 1)+". "+_vm._s(_vm.$t(_vm.path_in_translations + ".list." + i + ".title"))+" ")]),(_vm.$te(_vm.path_in_translations + '.list.' + i + '.content.p'))?_c('div',_vm._l((_vm.$t(
            _vm.path_in_translations + '.list.' + i + '.content.p'
          )),function(paragraph,j){return _c('p',{key:'p_' + j,domProps:{"innerHTML":_vm._s(paragraph)}})}),0):_vm._e(),(_vm.$te(_vm.path_in_translations + '.list.' + i + '.content.sections'))?_c('div',_vm._l((_vm.$t(
            _vm.path_in_translations + '.list.' + i + '.content.sections'
          )),function(section,j){return _c('div',{key:'sec_' + j},[_c('p',{staticClass:"text-h6 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t( _vm.path_in_translations + ".list." + i + ".content.sections." + j + ".title" ))+" ")]),(
              _vm.$te(
                _vm.path_in_translations +
                  '.list.' +
                  i +
                  '.content.sections.' +
                  j +
                  '.content.p'
              )
            )?_c('div',_vm._l((_vm.$t(
                _vm.path_in_translations +
                  '.list.' +
                  i +
                  '.content.sections.' +
                  j +
                  '.content.p'
              )),function(sec_paragraph,k){return _c('p',{key:'sec_p_' + k,domProps:{"innerHTML":_vm._s(sec_paragraph)}})}),0):_vm._e()])}),0):_vm._e()])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }