<template>
  <v-app-bar
    app
    color="background"
    class="pt-2"
    extended
    extension-height="16"
    elevate-on-scroll
    v-if="!mobileAppWebView"
  >
    <v-container class="">
      <v-row align="center">
        <router-link :to="homeUrl" custom v-slot="{ navigate }">
          <v-img
            @click="navigate"
            :src="require('@/assets/images/strataggem_logo.svg')"
            class="clickable mr-5"
            contain
            height="48"
            :width="logo_img_width"
            :max-width="logo_img_width"
          />
        </router-link>

        <v-spacer />

        <!-- Home button. Delete it when more than 4 or 5 buttons are already present (to save the space). -->
        <v-btn to="/contact" class="text-none font-large" text>
          {{ $t("global.FAQ") + " " + $t("global.and_contact") }}
        </v-btn>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
// Utilities
export default {
  name: "CoreAppBar",

  created() {
    if (this.mobileAppWebView) {
      this.$i18n.locale = "en";
    }
  },

  computed: {
    ...mapGetters(["is_carnaval_service"]),
    ...mapGetters(["service_url"]),

    homeUrl() {
      if (this.is_carnaval_service) {
        return "/?service=CARNAVAL";
      } else if (this.service_url != null && this.service_url != "") {
        return "/?service=" + this.service_url + 'login/';
      } else {
        return "/";
      }
    },

    logo_img_width() {
      return this.$vuetify.breakpoint.width > 400 ? 222 : 100;
    },

    mobileAppWebView() {
      const mobileAppWebViewByRouteName = "androidWebView" == this.$route.name;
      const mobileAppWebViewByQueryParam =
        null != this.$route.query &&
        null != this.$route.query.androidWebView &&
        "true" == this.$route.query.androidWebView;
      return (
        mobileAppWebViewByRouteName ||
        mobileAppWebViewByQueryParam ||
        this.is_carnaval_service
      );
    },
  },

  methods: {
    isCurrentOrChildPage(link) {
      // Does the menu button in question (link) correspond to the current open page?
      if (this.$route.name == link.name) return true;

      // Or is this menu button a parent of a the current open page?
      let pathArray = this.$route.path.split("/");
      if (pathArray.length > 2 && link.name === pathArray[1]) return true;

      // or is it an unrelated page ?
      return false;
    },
  },
};
</script>

<style scoped>
.v-app-bar .v-btn.accent {
  color: black;
}

.v-app-bar .v-btn {
  margin: 0 0.1em;
}

.locale-changer {
  width: 4em;
}
</style>
