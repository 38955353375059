<template>
  <v-form ref="form" lazy-validation class="col-12 col-lg-6">
    <v-row>
      <v-col>
        <!-- Title  -->
        <base-subheading>
          {{ $t("pages_specific.signup_page.Sign_up") }}
        </base-subheading>

        <!-- Info text  -->
        <p v-if="!sendDataToAndroidFailed">
          {{ $t("pages_specific.signup_page.Automatic_signup_info_code") }}
        </p>

        <p v-else-if="sendDataToAndroidFailed && registration_success">
          {{ $t("pages_specific.confirmation_page.user_created_ok_message") }}
        </p>
      </v-col>

      <template v-if="!registration_success">
        <!-- Email input form  -->
        <v-col cols="12">
          <v-text-field
            outlined
            v-model="email"
            :label="$t('pages_specific.signup_page.Email_address')"
            color="dark"
            :rules="emailRules"
            required
            hide-details="auto"
            @keydown.enter="validateAndSubmit"
            :disabled="signup_request_response_is_ok_flag"
          />
        </v-col>

        <!-- Send confirmation email button  -->
        <v-col cols="12" v-if="!signup_request_response_is_ok_flag">
          <v-btn
            width="100%"
            color="primary"
            class="dark--text"
            @click="validateAndSubmit"
            :loading="is_waiting_server_response_flag"
          >
            {{ $t("pages_specific.signup_page.Request_registration") }}
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          v-if="
            signup_request_response_is_received_flag &&
              !activation_code_response_is_received_flag
          "
        >
          <!-- Show response  -->
          <v-card
            outlined
            :class="{
              'border-color-warning': signup_request_response_is_error_flag,
              'border-color-ok': !signup_request_response_is_error_flag,
            }"
            class="pa-3"
          >
            {{ signup_request_response_message }}

            <span
              v-if="show_email_help_flag && signup_request_response_is_ok_flag"
            >
              {{ $t("pages_specific.signup_page.check_spam") }}
            </span>

            <v-icon
              v-if="signup_request_response_is_ok_flag"
              @click="show_email_help_flag = !show_email_help_flag"
              small
              class="float-right"
            >
              mdi-help
            </v-icon>
          </v-card>
        </v-col>

        <!-- Activation code input form  -->
        <v-col cols="12" v-if="signup_request_response_is_ok_flag">
          <div>
            <v-text-field
              class="input-letter-spacing width-10em hide-spin"
              :rules="[rules.required, rules.equal_6_digits]"
              outlined
              v-model="activation_code"
              :label="$t('pages_specific.signup_page.Activation_code')"
              color="dark"
              required
              v-mask="'######'"
              type="number"
              :append-outer-icon="null"
              hide-details="auto"
              @keydown.enter="verifyActivationCode"
              :disabled="activation_code_response_is_ok_flag"
            />
          </div>

          <div
            class="mt-3"
            v-if="
              !(
                activation_code_response_is_received_flag &&
                activation_code_response_is_ok_flag
              )
            "
          >
            <!-- Verify activation code  -->
            <v-btn
              color="primary"
              class="dark--text mr-3 mt-2"
              @click="verifyActivationCode"
              :loading="is_waiting_activation_code_response_flag"
            >
              {{ $t("pages_specific.signup_page.verify_code") }}
            </v-btn>

            <v-btn
              class="dark--text mr-3 mt-2"
              @click="reinitializeResponseStatus"
            >
              <v-icon dark> mdi-reload </v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" v-if="activation_code_response_is_received_flag">
          <!-- Show response  -->

          <div>
            <v-card
              outlined
              :class="{
                'border-color-warning': activation_code_response_is_error_flag,
                'border-color-ok': !activation_code_response_is_error_flag,
              }"
              class="pa-3 pr-5"
            >
              {{ activation_code_response_message }}
            </v-card>
          </div>

          <template v-if="activation_code_response_is_ok_flag">
            <!-- Choose a password  -->
            <div class="mt-5">
              <v-text-field
                outlined
                v-model="password"
                :label="$t('global.Password')"
                color="dark"
                required
                hide-details="auto"
                @keydown.enter="activateUser"
                :append-icon="show_password_input ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show_password_input = !show_password_input"
                :type="show_password_input ? 'text' : 'password'"
                :rules="[rules.required, rules.min]"
              />
            </div>

            <div class="mt-3">
              <!-- Activate user !  -->
              <v-btn
                color="primary"
                class="dark--text mr-3 mt-2"
                @click="activateUser"
                :loading="is_waiting_activation_code_response_flag"
              >
                {{ $t("pages_specific.signup_page.Activate_my_account") }}
              </v-btn>

              <v-btn
                class="dark--text mr-3 mt-2"
                @click="reinitializeResponseStatus"
              >
                <v-icon dark> mdi-reload </v-icon>
              </v-btn>
            </div>
          </template>
        </v-col>

        <v-col cols="12">
          <p>
            {{
              $t("pages_specific.signup_page.accept_terms_by_creating_part1")
            }}
            <router-link style="color: inherit" to="/terms">{{
              $t("global.the_terms")
            }}</router-link>
            {{ $t("global.and_with_spaces") }}
            <router-link style="color: inherit" to="/policy">{{
              $t("global.the_ploicy")
            }}</router-link
            >.
          </p>
        </v-col>
      </template>

      <v-col cols="6" class="mt-3">
        <info-text :text="$t('global.Already_have_account')" />
        <v-btn class="mt-2" width="100%" :href="loginUrl">
          {{ $t("global.Sign_in") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
function sendDataToAndroid(x) {
  try {
    Android.sendData(x);
    return true;
  } catch {
    console.log("The Android interface cannot be reached.");
    return false;
  }
}

import InfoText from "../core/InfoText.vue";
import { mapGetters, mapState } from "vuex";
import {
  activateUserAccountByCode,
  getCheckActivationCodeResponse,
  sendConfirmationEmail,
} from "../../utils/ServerCallsUtils";
import { email_regex } from "../../utils/RegexUtils";
import { SERVER_RESPONSES_ENUM } from "../../utils/ServerResponsesUtils";
import { CAS_CARNAVAL_URL, CAS_URL_WITH_SERVICE_PART_1 } from "../../utils/Constants.js";

export default {
  components: { InfoText },
  data() {
    return {
      show_email_help_flag: false,
      confirmation_email_response: SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET,
      activation_code_response: SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET,
      registration_success: false,
      email: "",
      signup_request_response_message: "",
      activation_code_response_message: "",
      activation_code: "",
      password: "",
      show_password_input: false,
      rules: {
        required: (value) => !!value || this.$t("global.Required"),
        min: (value) =>
          value.length >= 8 ||
          this.$t("pages_specific.confirmation_page.min_characters"),
        equal_6_digits: (value) =>
          value.length == 6 ||
          this.$t("pages_specific.confirmation_page.6_digits_code"),
      },
      sendDataToAndroidFailed: false,
    };
  },

  computed: {
    ...mapState(["user_profile"]),
    ...mapGetters(["is_carnaval_service"]),
    ...mapGetters(["service_url"]),

    loginUrl() {
      if (this.is_carnaval_service) {
        return CAS_CARNAVAL_URL;
      } else if (this.service_url != null && this.service_url != "") {
        return CAS_URL_WITH_SERVICE_PART_1 + this.service_url + "login/";
      } else {
        return "/accounts/login";
      }
    },

    mobileAppWebView() {
      const mobileAppWebViewByRouteName = "androidWebView" == this.$route.name;
      const mobileAppWebViewByQueryParam =
        null != this.$route.query &&
        null != this.$route.query.androidWebView &&
        "true" == this.$route.query.androidWebView;
      return (
        mobileAppWebViewByRouteName ||
        mobileAppWebViewByQueryParam ||
        this.is_carnaval_service
      );
    },

    is_waiting_server_response_flag() {
      return (
        SERVER_RESPONSES_ENUM.WAITING_RESPONSE ==
        this.confirmation_email_response
      );
    },

    signup_request_response_is_received_flag() {
      return (
        this.confirmation_email_response !=
          SERVER_RESPONSES_ENUM.WAITING_RESPONSE &&
        this.confirmation_email_response !=
          SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET
      );
    },

    signup_request_response_is_error_flag() {
      return (
        this.signup_request_response_is_received_flag &&
        this.confirmation_email_response != SERVER_RESPONSES_ENUM.RESPONSE_OK
      );
    },

    signup_request_response_is_ok_flag() {
      return (
        this.confirmation_email_response == SERVER_RESPONSES_ENUM.RESPONSE_OK
      );
    },

    is_waiting_activation_code_response_flag() {
      return (
        SERVER_RESPONSES_ENUM.WAITING_RESPONSE == this.activation_code_response
      );
    },

    activation_code_response_is_received_flag() {
      return (
        this.activation_code_response !=
          SERVER_RESPONSES_ENUM.WAITING_RESPONSE &&
        this.activation_code_response !=
          SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET
      );
    },

    activation_code_response_is_error_flag() {
      return (
        this.activation_code_response_is_received_flag &&
        this.activation_code_response != SERVER_RESPONSES_ENUM.RESPONSE_OK
      );
    },

    activation_code_response_is_ok_flag() {
      return this.activation_code_response == SERVER_RESPONSES_ENUM.RESPONSE_OK;
    },

    emailRules() {
      return [
        (v) => !!v || this.$t("form_validation.your_mail_required"),
        (v) => email_regex.test(v) || this.$t("form_validation.mail_valid"),
      ];
    },

    activation_code_no_spaces() {
      return this.activation_code.replace(/\s/g, "");
    },
  },

  methods: {
    async validateAndSubmit(event) {
      event.preventDefault();
      if (this.validate()) {
        await this.send_confirmation_email();
      }
    },

    validate() {
      return this.$refs.form.validate();
    },

    async verifyActivationCode() {
      if (this.validate()) {
        this.activation_code_response = SERVER_RESPONSES_ENUM.WAITING_RESPONSE;

        this.activation_code_response = await getCheckActivationCodeResponse(
          this.activation_code_no_spaces,
          this.email
        );

        this.activation_code_response_message = this.getMessageFromActivateUserResponseCode();
      }
    },

    async send_confirmation_email() {
      this.confirmation_email_response = SERVER_RESPONSES_ENUM.WAITING_RESPONSE;

      this.confirmation_email_response = await sendConfirmationEmail(
        this.email
      );

      this.signup_request_response_message = this.getMessageFromResponseCode();
    },

    async activateUser() {
      this.activate_account_response_code =
        SERVER_RESPONSES_ENUM.WAITING_RESPONSE;

      this.activate_account_response_code = await activateUserAccountByCode(
        this.activation_code_no_spaces,
        this.password,
        this.email
      );

      if (
        this.activate_account_response_code == SERVER_RESPONSES_ENUM.RESPONSE_OK
      ) {
        this.registration_success = true;
        this.sendDataToAndroidFailed = !sendDataToAndroid(this.email);
      } else {
        this.activate_account_response_message = this.getMessageFromResponseCode();
      }
    },

    getMessageFromResponseCode() {
      switch (this.confirmation_email_response) {
        case SERVER_RESPONSES_ENUM.RESPONSE_OK:
          return this.$t("pages_specific.signup_page.activation_code_sent");
        case SERVER_RESPONSES_ENUM.ERROR_ALREADY_EXIST:
          return this.$t("pages_specific.signup_page.user_already_exist");
        case SERVER_RESPONSES_ENUM.ERROR_EMAIL_WRONG_FORMAT:
          return this.$t("global.email_wrong_format");
        case SERVER_RESPONSES_ENUM.ERROR_EMAIL_NOT_SEND:
          return this.$t("pages_specific.signup_page.send_mail_error");
        default:
          // unknown error with no details provided
          return this.$t("global.unknow_error");
      }
    },

    getMessageFromActivateUserResponseCode() {
      switch (this.activation_code_response) {
        case SERVER_RESPONSES_ENUM.RESPONSE_OK:
          return this.$t("pages_specific.signup_page.code_ok_choose_password");
        case SERVER_RESPONSES_ENUM.ERROR_ALREADY_EXIST:
          return this.$t("pages_specific.signup_page.user_already_exist");
        case SERVER_RESPONSES_ENUM.ERROR_TICKET_INVALID:
          return this.$t("pages_specific.signup_page.invalid_code");
        case SERVER_RESPONSES_ENUM.ERROR_TOO_MANY_ATTEMPTS:
          return this.$t(
            "pages_specific.signup_page.no_more_attempts_per_code"
          );
        case SERVER_RESPONSES_ENUM.ERROR_EXPIRED:
          return this.$t("pages_specific.signup_page.code_expired");
        case SERVER_RESPONSES_ENUM.ERROR_CODE_CAN_NOT_BE_VERIFIED:
          return this.$t("pages_specific.signup_page.code_cannot_be_verified");
        default:
          // unknown error with no details provided
          return this.$t("global.unknow_error");
      }
    },

    reinitializeResponseStatus() {
      this.confirmation_email_response =
        SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET;

      this.activation_code_response =
        SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET;
    },
  },
};
</script>

<style scoped>
.width-10em {
  width: 10em;
}
</style>
