<template>
  <v-container class="pb-15">
    <v-form ref="form" lazy-validation class="col-12 col-lg-6">
      <v-row>
        <v-col>
          <v-progress-circular
            v-if="
              SERVER_RESPONSES_ENUM.WAITING_RESPONSE ==
              token_confirmation_response
            "
            indeterminate
          />

          <base-subheading v-else>
            {{ ticket_check_response_text }}
          </base-subheading>

          <!-- Show response  -->
          <v-card
            v-if="send_request_response_is_received"
            outlined
            :class="{
              'border-color-warning': send_request_response_is_error,
              'border-color-ok': !send_request_response_is_error,
            }"
            class="pa-3 my-5"
          >
            {{ send_request_response_message }}

            <v-icon class="float-right" @click="reinitializeSendRequestResponseStatus()">
              mdi-close
            </v-icon>
          </v-card>
        </v-col>
      </v-row>

      <div
        class="col-12"
        v-if="
          SERVER_RESPONSES_ENUM.RESPONSE_OK == token_confirmation_response &&
          !send_request_response_is_recieved_and_ok
        "
      >
        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="password"
              :rules="[rules.required, rules.min]"
              required
              :hint="$t('pages_specific.confirmation_page.min_characters')"
              :label="$t('global.Password')"
              hide-details="auto"
              :append-icon="show_password_input ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show_password_input = !show_password_input"
              :type="show_password_input ? 'text' : 'password'"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="password_confirmation"
              :rules="[rules.password_confirmation_match]"
              required
              :label="$t('global.Confirm_password')"
              hide-details="auto"
              :append-icon="
                show_confirm_password_input ? 'mdi-eye' : 'mdi-eye-off'
              "
              @click:append="
                show_confirm_password_input = !show_confirm_password_input
              "
              :type="show_confirm_password_input ? 'text' : 'password'"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              width="100%"
              color="primary"
              class="dark--text"
              @click="validateAndSubmit"
              :loading="is_waiting_request_server_response_flag"
            >
              {{ send_request_button_text }}
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <v-row v-else>
        <v-col cols="6" class="mt-7">
          <v-btn class="mt-2" width="100%" href="/" v-if="user_is_logged_in">
            {{ $t("global.pages_titles.home") }}
          </v-btn>

          <div v-else>
            <v-btn class="mt-2" width="100%" href="/accounts/login">
              {{ $t("global.Sign_in") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import InfoText from "../components/core/InfoText.vue";
import {
  getCheckConfirmationTicketResponse,
  getConfirmationTicketFromURL,
  REQUEST_TYPE,
  sendRequestWithTicket,
} from "../utils/ServerCallsUtils";

import { mapState } from "vuex";
import { SERVER_RESPONSES_ENUM } from "../utils/ServerResponsesUtils";

function getConfirmationTicket() {
  return getConfirmationTicketFromURL(location);
}

export default {
  components: { InfoText },

  props: {
    request_type: {
      type: Number,
      default: REQUEST_TYPE.PASSWORD_RESET,
    },
  },

  data() {
    return {
      ticket: "",
      token_confirmation_response: SERVER_RESPONSES_ENUM.WAITING_RESPONSE,
      send_request_response: SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET,
      password: "",
      password_confirmation: "",
      show_password_input: false,
      show_confirm_password_input: false,

      rules: {
        required: (value) => !!value || this.$t("global.Required"),
        min: (value) =>
          value.length >= 8 ||
          this.$t("pages_specific.confirmation_page.min_characters"),
        password_confirmation_match: (value) =>
          value == this.password ||
          this.$t("pages_specific.confirmation_page.passwords_dont_match"),
      },

      send_request_response_message: "",
    };
  },

  created() {
    this.ticket = getConfirmationTicket();
    this.verifyConfirmationTicket();
  },

  computed: {
    ...mapState(["user_profile"]),

    SERVER_RESPONSES_ENUM() {
      return SERVER_RESPONSES_ENUM;
    },

    user_is_logged_in() {
      return (
        SERVER_RESPONSES_ENUM.RESPONSE_OK == this.user_profile.response_status
      );
    },

    send_request_response_is_received() {
      return (
        this.send_request_response != SERVER_RESPONSES_ENUM.WAITING_RESPONSE &&
        this.send_request_response != SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET
      );
    },

    send_request_response_is_error() {
      return (
        this.send_request_response_is_received &&
        this.send_request_response != SERVER_RESPONSES_ENUM.RESPONSE_OK
      );
    },

    send_request_response_is_recieved_and_ok() {
      return this.send_request_response == SERVER_RESPONSES_ENUM.RESPONSE_OK;
    },

    is_waiting_request_server_response_flag() {
      return (
        SERVER_RESPONSES_ENUM.WAITING_RESPONSE == this.send_request_response
      );
    },

    ticket_check_response_text() {
      if (
        SERVER_RESPONSES_ENUM.RESPONSE_OK == this.token_confirmation_response
      ) {
        switch (this.request_type) {
          case REQUEST_TYPE.PASSWORD_RESET:
            return this.$t(
              "pages_specific.reset_password_page.enter_new_password"
            );
          default:
            return (
              this.$t("pages_specific.confirmation_page.email_confirmed") +
              " " +
              this.$t(
                "pages_specific.confirmation_page.enter_password_to_finalize"
              )
            );
        }
      } else if (
        SERVER_RESPONSES_ENUM.ERROR_TICKET_INVALID ==
        this.token_confirmation_response
      ) {
        return this.$t("server_response_common.ticket_invalid_or_expired");
      } else if (
        SERVER_RESPONSES_ENUM.ERROR_ALREADY_EXIST ==
        this.token_confirmation_response
      ) {
        return this.$t(
          "pages_specific.confirmation_page.user_already_created_message"
        );
      } else {
        return this.$t("global.unknow_error");
      }
    },

    send_request_button_text() {
      switch (this.request_type) {
        case REQUEST_TYPE.PASSWORD_RESET:
          return this.$t("pages_specific.reset_password_page.update_password");
        default:
          return this.$t(
            "pages_specific.confirmation_page.finalise_registration"
          );
      }
    },
  },

  methods: {
    async verifyConfirmationTicket() {
      this.token_confirmation_response =
        await getCheckConfirmationTicketResponse(
          this.ticket,
          this.request_type
        );
    },

    async validateAndSubmit(event) {
      event.preventDefault();

      if (this.validate()) {
        this.send_request_response = SERVER_RESPONSES_ENUM.WAITING_RESPONSE;

        const request_body = { password: this.password };
        this.send_request_response = await sendRequestWithTicket(
          this.ticket,
          request_body,
          this.request_type
        );

        this.send_request_response_message = this.getMessageFromResponseCode();
      }
    },

    send_request_response_text() {
      switch (this.send_request_response) {
        case SERVER_RESPONSES_ENUM.RESPONSE_OK:
          return this.$t(
            "pages_specific.confirmation_page.user_created_ok_message"
          );
        case SERVER_RESPONSES_ENUM.ERROR_PASSWORD_INVALID:
          return "PASSWORD_INVALID";
        case SERVER_RESPONSES_ENUM.ERROR_TICKET_INVALID:
          return this.$t("server_response_common.ticket_invalid_or_expired");
        default:
          return this.$t("global.unknow_error");
      }
    },

    getMessageFromResponseCode() {
      // TODO: this function is unfinished !!! Finish it !

      switch (this.send_request_response) {
        case SERVER_RESPONSES_ENUM.ERROR_PASSWORD_INVALID:
          return this.$t("server_response_common.invalid_password");
        case SERVER_RESPONSES_ENUM.RESPONSE_OK:
          switch (this.request_type) {
            case REQUEST_TYPE.PASSWORD_RESET:
              return this.$t(
                "pages_specific.reset_password_page.password_reset_successful"
              );

            default:
              return this.$t(
                "pages_specific.confirmation_page.user_created_ok_message"
              );
          }
        default:
          // unknown error with no details provided
          return this.$t("global.unknow_error");
      }
    },

    validate() {
      return this.$refs.form.validate();
    },

    reinitializeSendRequestResponseStatus() {
      this.send_request_response = SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET;
    },
  },
};
</script>
