<template>
  <v-container class="pb-15">
    <v-form ref="form" lazy-validation class="col-12 col-lg-6">
      <v-row>
        <v-col>
          <!-- Title  -->
          <base-subheading>
            {{ $t("pages_specific.reset_password_page.reset_password_title") }}
          </base-subheading>

          <!-- Info text  -->
          <p>
            {{ $t("pages_specific.reset_password_page.reset_password_info") }}
          </p>

          <!-- Show response  -->
          <v-card
            v-if="response_is_received_flag"
            outlined
            :class="{
              'border-color-warning': response_is_error_flag,
              'border-color-ok': !response_is_error_flag,
            }"
            class="pa-3 my-5"
          >
            {{ response_message }}

            <v-icon class="float-right" @click="reinitializeResponseStatus()">
              mdi-close
            </v-icon>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <!-- Email input form  -->
        <v-col cols="12">
          <v-text-field
            outlined
            v-model="email"
            :label="$t('pages_specific.signup_page.Email_address')"
            color="dark"
            :rules="emailRules"
            required
            hide-details="auto"
            @keydown.enter="validateAndSubmit"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- Send confirmation email button  -->
        <v-col cols="12">
          <v-btn
            width="100%"
            color="primary"
            class="dark--text allow-multiline"
            @click="validateAndSubmit"
            :loading="is_waiting_server_response_flag"
          >
            {{
              $t("pages_specific.reset_password_page.request_password_reset")
            }}
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="mobileAppWebView">
          <v-btn :href="loginUrl" class="mr-4">
            {{ $t("global.Sign_in") }}
          </v-btn>
          <v-btn :href="homeUrl">
            {{ $t("pages_specific.signup_page.Sign_up") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { CAS_CARNAVAL_URL } from "../utils/Constants";
import { email_regex } from "../utils/RegexUtils";
import { sendPasswordResetEmail } from "../utils/ServerCallsUtils";
import { SERVER_RESPONSES_ENUM } from "../utils/ServerResponsesUtils";

export default {
  data() {
    return {
      email: "",
      email_sending_response: SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET,
      response_message: "",
    };
  },

  computed: {
    ...mapGetters(["is_carnaval_service"]),

    loginUrl() {
      if (this.is_carnaval_service) {
        return CAS_CARNAVAL_URL;
      } else {
        return "/accounts/login";
      }
    },

    homeUrl() {
      if (this.is_carnaval_service) {
        return "/?service=CARNAVAL";
      } else {
        return "/";
      }
    },

    mobileAppWebView() {
      const mobileAppWebViewByRouteName = "androidWebView" == this.$route.name;
      const mobileAppWebViewByQueryParam =
        null != this.$route.query &&
        null != this.$route.query.androidWebView &&
        "true" == this.$route.query.androidWebView;
      return (
        mobileAppWebViewByRouteName ||
        mobileAppWebViewByQueryParam ||
        this.is_carnaval_service
      );
    },

    emailRules() {
      return [
        (v) => !!v || this.$t("form_validation.your_mail_required"),
        (v) => email_regex.test(v) || this.$t("form_validation.mail_valid"),
      ];
    },

    is_waiting_server_response_flag() {
      return (
        SERVER_RESPONSES_ENUM.WAITING_RESPONSE == this.email_sending_response
      );
    },

    response_is_received_flag() {
      return (
        this.email_sending_response != SERVER_RESPONSES_ENUM.WAITING_RESPONSE &&
        this.email_sending_response !=
          SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET
      );
    },

    response_is_error_flag() {
      return (
        this.response_is_received_flag &&
        this.email_sending_response != SERVER_RESPONSES_ENUM.RESPONSE_OK
      );
    },
  },

  methods: {
    async validateAndSubmit(event) {
      event.preventDefault();

      if (this.validate()) {
        await this.send_email();
      }
    },

    validate() {
      return this.$refs.form.validate();
    },

    async send_email() {
      this.email_sending_response = SERVER_RESPONSES_ENUM.WAITING_RESPONSE;

      this.email_sending_response = await sendPasswordResetEmail(this.email);

      this.response_message = this.getMessageFromResponseCode();
    },

    getMessageFromResponseCode() {
      switch (this.email_sending_response) {
        case SERVER_RESPONSES_ENUM.RESPONSE_OK:
          return this.$t("pages_specific.signup_page.confirmation_email_sent");
        case SERVER_RESPONSES_ENUM.ERROR_DOES_NOT_EXIST:
          return this.$t("pages_specific.reset_password_page.no_such_mail");
        default:
          // unknown error with no details provided
          return this.$t("global.unknow_error");
      }
    },

    reinitializeResponseStatus() {
      this.email_sending_response = SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET;
    },
  },
};
</script>
