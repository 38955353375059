<template>
  <div>
    <base-subheading>{{ $t(path_in_translations + ".title") }}</base-subheading>

    <p class="font-weight-light">
      {{ $t(path_in_translations + ".effective_date") }}
    </p>

    <div class="mt-10">
      <!-- Loop over chapters -->
      <div
        class="mt-5"
        v-for="(chapter, i) in $t(path_in_translations + '.list')"
        :key="'ch_' + i"
      >
        <!-- Chapter title -->
        <p class="text-h5 font-weight-medium">
          {{ i + 1 }}. {{ $t(path_in_translations + ".list." + i + ".title") }}
        </p>

        <!-- Paragraphs -->
        <div v-if="$te(path_in_translations + '.list.' + i + '.content.p')">
          <p
            v-for="(paragraph, j) in $t(
              path_in_translations + '.list.' + i + '.content.p'
            )"
            :key="'p_' + j"
            v-html="paragraph"
          />
        </div>

        <!-- Subsections (title + paragraphs) if there are any -->
        <div
          v-if="$te(path_in_translations + '.list.' + i + '.content.sections')"
        >
          <div
            v-for="(section, j) in $t(
              path_in_translations + '.list.' + i + '.content.sections'
            )"
            :key="'sec_' + j"
          >
            <p class="text-h6 font-weight-medium">
              {{
                $t(
                  path_in_translations +
                    ".list." +
                    i +
                    ".content.sections." +
                    j +
                    ".title"
                )
              }}
            </p>

            <div
              v-if="
                $te(
                  path_in_translations +
                    '.list.' +
                    i +
                    '.content.sections.' +
                    j +
                    '.content.p'
                )
              "
            >
              <p
                v-for="(sec_paragraph, k) in $t(
                  path_in_translations +
                    '.list.' +
                    i +
                    '.content.sections.' +
                    j +
                    '.content.p'
                )"
                :key="'sec_p_' + k"
                v-html="sec_paragraph"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>   

<script>
export default {
  props: {
    path_in_translations: {
      type: String,
      default: "",
    },
  },
};
</script>
