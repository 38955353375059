var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pb-15"},[_c('v-form',{ref:"form",staticClass:"col-12 col-lg-6",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',[(
            _vm.SERVER_RESPONSES_ENUM.WAITING_RESPONSE ==
            _vm.token_confirmation_response
          )?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_c('base-subheading',[_vm._v(" "+_vm._s(_vm.ticket_check_response_text)+" ")]),(_vm.send_request_response_is_received)?_c('v-card',{staticClass:"pa-3 my-5",class:{
            'border-color-warning': _vm.send_request_response_is_error,
            'border-color-ok': !_vm.send_request_response_is_error,
          },attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.send_request_response_message)+" "),_c('v-icon',{staticClass:"float-right",on:{"click":function($event){return _vm.reinitializeSendRequestResponseStatus()}}},[_vm._v(" mdi-close ")])],1):_vm._e()],1)],1),(
        _vm.SERVER_RESPONSES_ENUM.RESPONSE_OK == _vm.token_confirmation_response &&
        !_vm.send_request_response_is_recieved_and_ok
      )?_c('div',{staticClass:"col-12"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","rules":[_vm.rules.required, _vm.rules.min],"required":"","hint":_vm.$t('pages_specific.confirmation_page.min_characters'),"label":_vm.$t('global.Password'),"hide-details":"auto","append-icon":_vm.show_password_input ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show_password_input ? 'text' : 'password'},on:{"click:append":function($event){_vm.show_password_input = !_vm.show_password_input}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","rules":[_vm.rules.password_confirmation_match],"required":"","label":_vm.$t('global.Confirm_password'),"hide-details":"auto","append-icon":_vm.show_confirm_password_input ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show_confirm_password_input ? 'text' : 'password'},on:{"click:append":function($event){_vm.show_confirm_password_input = !_vm.show_confirm_password_input}},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"dark--text",attrs:{"width":"100%","color":"primary","loading":_vm.is_waiting_request_server_response_flag},on:{"click":_vm.validateAndSubmit}},[_vm._v(" "+_vm._s(_vm.send_request_button_text)+" ")])],1)],1)],1):_c('v-row',[_c('v-col',{staticClass:"mt-7",attrs:{"cols":"6"}},[(_vm.user_is_logged_in)?_c('v-btn',{staticClass:"mt-2",attrs:{"width":"100%","href":"/"}},[_vm._v(" "+_vm._s(_vm.$t("global.pages_titles.home"))+" ")]):_c('div',[_c('v-btn',{staticClass:"mt-2",attrs:{"width":"100%","href":"/accounts/login"}},[_vm._v(" "+_vm._s(_vm.$t("global.Sign_in"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }