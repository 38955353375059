<template>
  <div class="col-12 col-lg-7 mb-5">
    <!-- User profile  -->
    <user-profile-information />

    <!-- User teams and services  -->
    <user-teams-and-services />

    <!-- Users in teams in which the current user is admin  -->
    <users-in-teams />

    <!-- Invitations  -->
    <invitation-requests />

    <!-- Team's history  -->
    <team-history />
  </div>
</template>

<script>
import InvitationRequests from "./InvitationRequests.vue";
import TeamHistory from "./TeamHistory.vue";
import UserProfileInformation from "./UserProfileInformation.vue";
import UsersInTeams from "./UsersInTeams.vue";
import UserTeamsAndServices from "./UserTeamsAndServices.vue";

export default {
  components: {
    InvitationRequests,
    UserProfileInformation,
    UserTeamsAndServices,
    UsersInTeams,
    TeamHistory,
  },
};
</script>