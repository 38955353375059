var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    null != _vm.user_teams_in_which_he_is_admin &&
      _vm.user_teams_in_which_he_is_admin.length > 0
  )?_c('v-card',{staticClass:"pa-5 mt-10",attrs:{"width":"100%"}},[_c('v-row',[_c('info-text',{staticClass:"ma-2 mb-5",attrs:{"text":_vm.$t('pages_specific.profile_page.users_in_teams.Users_in_your_teams')}}),(!_vm.response_is_recieved)?_c('wait-server-progress'):_c('v-col',{attrs:{"cols":"12"}},[(_vm.users_in_teams_grouped_and_filtered.length > 0)?_c('div',[_c('ul',{staticClass:"invitations"},_vm._l((_vm.users_in_teams_grouped_and_filtered),function(service,i){return _c('li',{key:i},[_c('info-text',{attrs:{"text":_vm.$t('global.service') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s("TUS" == service.service_name ? "PUCS" : service.service_name)+" "),_c('ul',_vm._l((service.teams_and_users),function(team,j){return _c('li',{key:j,staticClass:"padding-0"},[_c('info-text',{attrs:{"text":_vm.$t('global.team') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s(team.team_name)+" "),_c('ul',_vm._l((team.users_and_roles),function(item,k){return _c('li',{key:k,staticClass:"padding-0"},[_c('info-text',{staticClass:"ml-4",attrs:{"text":_vm.$t('global.user') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s(item.user_name)+" "),_c('info-text',{staticClass:"ml-4",attrs:{"text":_vm.$t('global.role') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s(_vm.role_name(item.role_name))+" "),_c('v-dialog',{attrs:{"max-width":"600","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var dialog = ref.on;
  var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"elevation":"2"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"color":"dark"}},[_vm._v(_vm._s(_vm.isAdminUser(item) ? "mdi-stairs-down" : "mdi-stairs-up"))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.isAdminUser(item) ? _vm.$t( "pages_specific.profile_page.users_in_teams.downgrade_admin_short" ) : _vm.$t( "pages_specific.profile_page.users_in_teams.promote_user_short" ))+" ")])])]}}],null,true),model:{value:(_vm.dialogs_confirm_change_role[item.count_i]),callback:function ($$v) {_vm.$set(_vm.dialogs_confirm_change_role, item.count_i, $$v)},expression:"dialogs_confirm_change_role[item.count_i]"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.isAdminUser(item) ? _vm.$t( "pages_specific.profile_page.users_in_teams.are_you_sure_to_downgrade_admin", { user: item.user_name, team: team.team_name, } ) : _vm.$t( "pages_specific.profile_page.users_in_teams.are_you_sure_to_promote_user", { user: item.user_name, team: team.team_name, } ))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("global.action_cannot_be_undone")))]),_c('div',{staticClass:"pt-1 pb-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",on:{"click":_vm.closeAllDialogs}},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.users_in_teams.keep_role" ))+" ")]),_c('v-btn',{staticClass:"mx-2 dark--text",attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeUserRole(item)}}},[_vm._v(" "+_vm._s(_vm.isAdminUser(item) ? _vm.$t( "pages_specific.profile_page.users_in_teams.yes_downgrade_admin" ) : _vm.$t( "pages_specific.profile_page.users_in_teams.yes_promote_user" ))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var dialog = ref.on;
  var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"elevation":"2"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"color":"dark"}},[_vm._v("mdi-account-remove-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.users_in_teams.remove_role_short" )))])])]}}],null,true),model:{value:(_vm.dialogs_confirm_remove_user[item.count_i]),callback:function ($$v) {_vm.$set(_vm.dialogs_confirm_remove_user, item.count_i, $$v)},expression:"dialogs_confirm_remove_user[item.count_i]"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.users_in_teams.are_you_sure_to_remove_role", { user: item.user_name, team: team.team_name, } ))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("global.action_cannot_be_undone")))]),_c('div',{staticClass:"pt-1 pb-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",on:{"click":_vm.closeAllDialogs}},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.users_in_teams.keep_role" ))+" ")]),_c('v-btn',{staticClass:"mx-2 dark--text",attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteUserRole(item, i)}}},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.users_in_teams.yes_delete" ))+" ")])],1)],1)],1)],1)}),0)],1)}),0)],1)}),0)]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("pages_specific.profile_page.users_in_teams.no_other_users"))+" ")])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }