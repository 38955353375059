<template>
  <v-form ref="form" lazy-validation>
    <v-row>
      <v-col>
        <!-- Title  -->
        <base-subheading>
          {{ $t("pages_specific.profile_page.Hello") }}{{ user_username }}
        </base-subheading>

        <v-btn class="mt-5 mb-10" href="/accounts/logout">
          {{ $t("global.Sign_out") }}
        </v-btn>

        <!-- Show response  -->
        <v-card
          v-if="response_is_received_flag"
          outlined
          :class="{
            'border-color-warning': response_is_error_flag,
            'border-color-ok': !response_is_error_flag,
          }"
          class="pa-3 my-5"
        >
          {{ response_message }}

          <v-icon class="float-right" @click="reinitializeResponseStatus()">
            mdi-close
          </v-icon>
        </v-card>
      </v-col>
    </v-row>

    <!-- User information  -->
    <v-card width="100%" class="pa-5">
      <v-row>
        <info-text
          :text="$t('pages_specific.profile_page.Your_information')"
          class="ma-2 mb-5"
        />

        <!-- Username (can not be changed)  -->
        <v-col cols="12">
          <custom-input
            :label="$t('pages_specific.profile_page.Username')"
            v-model="user_username"
            :hint="$t('pages_specific.profile_page.username_cant_be_modified')"
            :persistent-hint="editing_mode_flag"
            :editing_mode_flag="false"
          />
        </v-col>

        <!-- Full name  -->
        <v-col cols="12">
          <custom-input
            :label="$t('pages_specific.profile_page.Full_name')"
            v-model="user_full_name"
            :rules="full_name_rules"
            :editing_mode_flag="editing_mode_flag"
            @clear="user_full_name = ''"
          />
        </v-col>
        
        <!-- Birthday  -->
        <v-col cols="12">
          <v-menu
            v-if="editing_mode_flag"
            ref="birthday_menu"
            v-model="birthday_menu"
            :close-on-content-click="false"
            :return-value.sync="user_birth_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <custom-input
                  :label="' ' + $t('pages_specific.profile_page.Birthday')"
                  v-model="user_birth_date"
                  readonly
                  @clear="user_birth_date = ''"
                  :editing_mode_flag="true"
                  prepend-inner-icon="mdi-calendar"
                />
              </div>
            </template>
            <v-date-picker v-model="user_birth_date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="birthday_menu = false">
                {{ $t("global.Cancel") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.birthday_menu.save(user_birth_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <custom-input
            v-else
            :label="$t('pages_specific.profile_page.Birthday')"
            v-model="user_birth_date"
            :editing_mode_flag="false"
          />
        </v-col>

        <!-- Phone  -->
        <v-col cols="12">
          <custom-input
            :label="$t('pages_specific.profile_page.Phone_number')"
            v-model="user_phone_number"
            :rules="phone_rules"
            :editing_mode_flag="editing_mode_flag"
            @clear="user_phone_number = ''"
          />
        </v-col>

        <!-- Logo  -->
        <v-col cols="12" v-if="null != user_logo_src || editing_mode_flag">
          <info-text
            :text="$t('pages_specific.profile_page.Icon_colon')"
            class="ml-2"
          />
          <file-uploader
            class="ml-2"
            @load="imageIsLoadedEventHandler($event)"
            v-if="editing_mode_flag"
            :is_image="true"
          />
          <v-img :src="user_logo_src" class="user-logo mt-4 mx-4" contain />
        </v-col>
        <v-col cols="12" v-else>
          <info-text
            :text="$t('pages_specific.profile_page.No_icon')"
            class="ml-2"
          />
        </v-col>

        <!-- Activate edit mode  -->
        <v-col cols="12" class="mt-5 mb-3">
          <v-btn
            v-if="!editing_mode_flag"
            color="primary"
            class="dark--text"
            @click="toggleEditingMode()"
          >
            {{ $t("pages_specific.profile_page.edit_profile") }}
          </v-btn>
          <div v-else>
            <v-row>
              <v-col md="6" cols="12" class="min-width-max-content">
                <v-btn
                  width="100%"
                  color="primary"
                  class="dark--text mr-5"
                  @click="validateAndSubmit"
                  :loading="is_waiting_server_response_flag"
                >
                  {{ $t("pages_specific.profile_page.save") }}
                </v-btn>
              </v-col>
              <v-col md="6" cols="12" class="min-width-max-content">
                <v-btn width="100%" @click="cancelEdit" :loading="false">
                  {{ $t("global.Cancel") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>

<script>
import InfoText from "../core/InfoText.vue";
import { mapState } from "vuex";
import { updateUserProfile } from "../../utils/ServerCallsUtils";
import CustomInput from "../custom_styling/CustomInput.vue";
import FileUploader from "../core/FileUploader.vue";
import { SERVER_RESPONSES_ENUM } from "../../utils/ServerResponsesUtils";

const date_or_empty_regex =
  /^$|\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;

export default {
  components: {
    InfoText,
    CustomInput,
    FileUploader,
  },

  data() {
    return {
      birthday_menu: false,
      editing_mode_flag: false,
      response_message: "",
      user_profile_update_response: SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET,

      // User profile fields:
      user_username: null,
      user_full_name: null,
      user_birth_date: null,
      user_phone_number: null,
      user_logo_file: null,
      user_logo_src: null,
    };
  },

  mounted() {
    this.fill_form_from_user_profile();
  },

  computed: {
    ...mapState(["user_profile"]),

    is_waiting_server_response_flag() {
      return (
        SERVER_RESPONSES_ENUM.WAITING_RESPONSE ==
        this.user_profile_update_response
      );
    },

    response_is_received_flag() {
      return (
        this.user_profile_update_response !=
          SERVER_RESPONSES_ENUM.WAITING_RESPONSE &&
        this.user_profile_update_response !=
          SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET
      );
    },

    response_is_error_flag() {
      return (
        this.response_is_received_flag &&
        this.user_profile_update_response != SERVER_RESPONSES_ENUM.RESPONSE_OK
      );
    },

    birth_date_rules() {
      return [(v) => date_or_empty_regex.test(v) || "YYYY-MM-DD"];
    },

    phone_rules() {
      return [
        (v) =>
          null == v ||
          v.length <= 32 ||
          this.$t("pages_specific.profile_page.maximum_char_32"),
      ];
    },
    
    full_name_rules() {
      return [
        (v) =>
          null == v ||
          v.length <= 64 ||
          this.$t("pages_specific.profile_page.maximum_char_64"),
      ];      
    },
    
    is_full_name_changed() {
      return this.user_profile.json_body.phone_number != this.user_full_name;
    },

    is_birthday_changed() {
      return this.user_profile.json_body.birth_date != this.user_birth_date;
    },

    is_phone_changed() {
      return this.user_profile.json_body.phone_number != this.user_phone_number;
    },

    is_logo_changed() {
      return this.user_profile.json_body.logo != this.user_logo_src;
    },
  },

  methods: {
    fill_form_from_user_profile() {
      if (this.user_profile && this.user_profile.json_body) {
        this.user_username = this.user_profile.json_body.username;
        this.user_full_name = this.user_profile.json_body.full_name;
        this.user_birth_date = this.user_profile.json_body.birth_date;
        this.user_phone_number = this.user_profile.json_body.phone_number;
        this.user_logo_src = this.user_profile.json_body.logo;
      }
    },

    toggleEditingMode() {
      this.editing_mode_flag = true;

      // Hide the update result message
      if (this.response_is_received_flag) {
        this.user_profile_update_response =
          SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET;
      }
    },

    cancelEdit() {
      this.fill_form_from_user_profile();
      this.editing_mode_flag = false;
    },

    imageIsLoadedEventHandler(event_data) {
      this.user_logo_src = event_data.image_url;
      this.user_logo_file = event_data.file;
    },

    async validateAndSubmit(event) {
      event.preventDefault();

      if (this.validate()) {
        await this.update_user_profile();
      }
    },

    validate() {
      return this.$refs.form.validate();
    },

    async update_user_profile() {
      this.user_profile_update_response =
        SERVER_RESPONSES_ENUM.WAITING_RESPONSE;

      var updated_user_profile = {};
      if (this.is_birthday_changed)
        updated_user_profile.birth_date = this.user_birth_date;
      if (this.is_phone_changed)
        updated_user_profile.phone_number = this.user_phone_number;
      if (this.is_full_name_changed)
        updated_user_profile.full_name = this.user_full_name;
      if (this.is_logo_changed) updated_user_profile.logo = this.user_logo_file;

      this.user_profile_update_response = await updateUserProfile(
        updated_user_profile
      );

      this.response_message = this.getMessageFromResponseCode();

      if (
        SERVER_RESPONSES_ENUM.RESPONSE_OK == this.user_profile_update_response
      ) {
        this.editing_mode_flag = false;
      }
    },

    getMessageFromResponseCode() {
      switch (this.user_profile_update_response) {
        case SERVER_RESPONSES_ENUM.RESPONSE_OK:
          return this.$t("pages_specific.profile_page.successfully_updated");
        default:
          // unknown error with no details provided
          return this.$t("global.unknow_error");
      }
    },

    reinitializeResponseStatus() {
      this.user_profile_update_response =
        SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET;
    },
  },
};
</script>

<style scoped>
.user-logo {
  max-width: 15em;
  max-height: 30em;
}
</style>