<template>
  <v-row v-if="null != invitations_pending && invitations_pending.length > 0">
    <v-col cols="12">
      <div class="mb-5">
        <div class="mb-2">
          {{ $t("pages_specific.profile_page.invitations.Requests_recieved") }}
        </div>
        <ul class="invitations">
          <li
            v-for="(item, i) in invitations_pending"
            :key="i"
            :class="{
              accepted: null == item.accepted,
              declined: false == item.accepted,
            }"
          >
            <info-text :text="$t('global.from') + $t('global.colon')" />
            {{ item.issuer_email }}

            <info-text
              class="ml-4"
              :text="$t('global.team') + $t('global.colon')"
            />
            {{ item.team_name }}

            <info-text
              class="ml-4"
              :text="$t('global.service') + $t('global.colon')"
            />
            {{ "TUS" == item.service_name ? "PUCS" : item.service_name }}

            <info-text
              class="ml-4"
              :text="$t('global.role') + $t('global.colon')"
            />
            {{ role_name(item.role_name) }}

            <info-text
              class="ml-4"
              :text="$t('global.date') + $t('global.colon')"
            />
            {{ formatDate(item.date) }}

            <div class="mt-3" v-if="null == item.accepted">
              <v-btn
                color="primary"
                class="dark--text mr-5"
                @click="acceptInvitation(item)"
                :loading="false"
              >
                {{ $t("pages_specific.profile_page.invitations.Accept") }}
              </v-btn>

              <v-dialog
                v-model="dialogs[i]"
                max-width="600"
                :retain-focus="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :loading="false" v-bind="attrs" v-on="on">
                    {{ $t("pages_specific.profile_page.invitations.Decline") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h6">
                    {{
                      $t(
                        "pages_specific.profile_page.invitations.are_you_sure_to_decline_invitation"
                      )
                    }}
                  </v-card-title>

                  <v-card-text>
                    <div>
                      <info-text
                        :text="$t('global.from') + $t('global.colon')"
                      />
                      {{ item.issuer_email }}

                      <info-text
                        class="ml-4"
                        :text="$t('global.team') + $t('global.colon')"
                      />
                      {{ item.team_name }}
                    </div>
                    <span>
                      {{ $t("global.action_cannot_be_undone") }}
                    </span>
                  </v-card-text>

                  <div class="pt-1 pb-3">
                    <v-spacer></v-spacer>
                    <v-btn @click="closeAllDialogs" class="mx-2">
                      {{
                        $t(
                          "pages_specific.profile_page.invitations.keep_request"
                        )
                      }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="mx-2 dark--text"
                      @click="declineInvitation(item)"
                    >
                      {{
                        $t("pages_specific.profile_page.invitations.yes_delete")
                      }}
                    </v-btn>
                  </div>
                </v-card>
              </v-dialog>
            </div>

            <span v-else-if="false == item.accepted">
              <info-text
                class="ml-4"
                :text="$t('global.status') + $t('global.colon')"
              />
              {{ $t("global.refused") }}
            </span>
          </li>
        </ul>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {
  acceptInvitationRequest,
  declineInvitationRequest,
  getInvitationRequestsRecieved,
  PREDEFINED_ROLES_ENUM,
} from "../../utils/ServerCallsUtils";
import InfoText from "../core/InfoText.vue";
import Btn from "../custom_styling/Btn.vue";

function getOnlyPendingInvitation(invitation_received) {
  var invitations_filtered = [];
  for (const invitation of invitation_received) {
    if (null == invitation.accepted) invitations_filtered.push(invitation);
  }
  return invitations_filtered;
}

export default {
  components: { InfoText, Btn },
  data() {
    return {
      // Recieved invitations
      invitation_received: [],
      invitations_pending: [],
      dialogs: [],
    };
  },

  created() {
    this.loadInvitationsRecieved();
  },

  mounted() {
    this.initConfirmationDialogs();
  },

  watch: {
    invitations_pending() {
      this.initConfirmationDialogs();
    },

    "$store.state.data_is_updated_toggle": function() {
      // reload the component's data
      this.loadInvitationsRecieved();
    },
  },

  methods: {
    initConfirmationDialogs() {
      this.dialogs = [];
      for (let i = 0; i < this.invitations_pending.length; i++) {
        this.dialogs.push(false);
      }
    },

    closeAllDialogs() {
      this.initConfirmationDialogs();
    },

    async loadInvitationsRecieved() {
      this.invitation_received = await getInvitationRequestsRecieved();
      this.invitations_pending = getOnlyPendingInvitation(
        this.invitation_received
      );
    },

    async acceptInvitation(invitation_object) {
      await acceptInvitationRequest(invitation_object.id);
      this.changeInvitationStatus(invitation_object.id, true);
    },

    async declineInvitation(invitation_object) {
      this.dialog = false;
      await declineInvitationRequest(invitation_object.id);
      this.changeInvitationStatus(invitation_object.id, false);
    },

    changeInvitationStatus(invitation_object_id, accepted = true) {
      for (var invitation of this.invitation_received) {
        if (invitation_object_id == invitation.id) {
          invitation.accepted = accepted;
          if (accepted) this.$emit("anInvitationIsAccepted", invitation);
        }
      }
    },

    role_name(role_name) {
      switch (role_name) {
        case PREDEFINED_ROLES_ENUM.ADMIN:
          return this.$t("global.roles.admin");
        case PREDEFINED_ROLES_ENUM.OWNER:
          return this.$t("global.roles.owner");
        case PREDEFINED_ROLES_ENUM.USER:
          return this.$t("global.roles.user");
        default:
          return role_name;
      }
    },

    formatDate: function(object_date) {
      var d = Date.parse(object_date);
      return new Date(d).toLocaleDateString();
    },
  },
};
</script>
