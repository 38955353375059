<template>
  <v-card width="100%" class="pa-5 mt-10">
    <v-row>
      <info-text
        :text="
          $t('pages_specific.profile_page.services.Your_services_and_teams')
        "
        class="ma-2 mb-5"
      />

      <v-col
        cols="12"
        v-if="
          null != user_profile &&
          null != user_profile.json_body &&
          null != user_profile.json_body.roles_in_teams &&
          user_profile.json_body.roles_in_teams.length > 0
        "
      >
        <!-- Show response  -->
        <v-card
          v-if="null != response_message"
          outlined
          class="border-color-warning pa-3 mb-10"
        >
          {{ response_message }}

          <v-icon class="float-right" @click="response_message = null">
            mdi-close
          </v-icon>
        </v-card>

        <ul class="invitations">
          <li
            v-for="(item, i) in user_profile.json_body.roles_in_teams"
            :key="i"
          >
            <info-text :text="$t('global.service') + $t('global.colon')" />

            {{
              "TUS" == item.team.service.name ? "PUCS" : item.team.service.name
            }}

            <info-text
              class="ml-4"
              :text="$t('global.team') + $t('global.colon')"
            />
            {{ item.team.name }}

            <!-- <span v-if="null != item.team.parent_team" class="ml-4">
              (<info-text
                :text="$t('global.parent_team') + $t('global.colon')"
              />
              {{ item.team.parent_team.name }})
            </span> -->

            <info-text
              class="ml-4"
              :text="$t('global.role') + $t('global.colon')"
            />
            {{ role_name(item) }}

            <v-dialog
              v-model="dialogs[i]"
              max-width="600"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      elevation="2"
                      class="ml-4"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...dialog }"
                    >
                      <v-icon color="dark">mdi-exit-run</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{
                      $t(
                        "pages_specific.profile_page.services.delete_from_role_short"
                      )
                    }}</span
                  >
                </v-tooltip>
              </template>
              <v-card>
                <v-card-title class="text-h6">
                  {{
                    $t(
                      "pages_specific.profile_page.services.are_you_sure_to_remove_role",
                      { team: item.team.name }
                    )
                  }}
                </v-card-title>

                <v-card-text>
                  {{ $t("global.action_cannot_be_undone") }}</v-card-text
                >

                <div class="pt-1 pb-3">
                  <v-spacer></v-spacer>
                  <v-btn @click="closeAllDialogs" class="mx-2">
                    {{ $t("pages_specific.profile_page.services.keep_role") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="deleteUserRole(item, i)"
                    class="mx-2 dark--text"
                  >
                    {{ $t("pages_specific.profile_page.services.yes_delete") }}
                  </v-btn>
                </div>
              </v-card>
            </v-dialog>
          </li>
        </ul>
      </v-col>
      <v-col cols="12" v-else>
        {{
          $t("pages_specific.profile_page.services.not_registred_for_services")
        }}
      </v-col>
    </v-row>

    <invitation-recieved
      @anInvitationIsAccepted="anInvitationIsAcceptedEvent($event)"
    />
  </v-card>
</template>

<script>
import InfoText from "../core/InfoText.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  deleteUserFromRole,
  getUserProfile,
  PREDEFINED_ROLES_ENUM,
} from "../../utils/ServerCallsUtils";

import InvitationRequests from "./InvitationRequests.vue";
import InvitationRecieved from "./InvitationRecieved.vue";
import { SERVER_RESPONSES_ENUM } from "../../utils/ServerResponsesUtils";

export default {
  components: {
    InfoText,
    InvitationRequests,
    InvitationRecieved,
  },

  data() {
    return {
      response_message: null,
      dialogs: [],
    };
  },

  computed: {
    ...mapState(["user_profile"]),
    ...mapGetters(["user_teams_in_which_he_is_admin"]),
  },

  mounted() {
    this.initConfirmationDialogs();
  },

  watch: {
    user_profile() {
      this.initConfirmationDialogs();
    },
  },

  methods: {
    ...mapActions(["updateUserProfile"]),

    initConfirmationDialogs() {
      this.dialogs = [];
      for (
        let i = 0;
        i < this.user_profile.json_body.roles_in_teams.length;
        i++
      ) {
        this.dialogs.push(false);
      }
    },

    role_name(item) {
      switch (item.role.name) {
        case PREDEFINED_ROLES_ENUM.ADMIN:
          return this.$t("global.roles.admin");
        case PREDEFINED_ROLES_ENUM.OWNER:
          return this.$t("global.roles.owner");
        case PREDEFINED_ROLES_ENUM.USER:
          return this.$t("global.roles.user");
        default:
          return item.role.name;
      }
    },

    async anInvitationIsAcceptedEvent() {
      const user_profile_recieved = await getUserProfile();
      this.updateUserProfile(user_profile_recieved);
    },

    async deleteUserRole(item, i) {
      this.closeAllDialogs();

      const response_recieved = await deleteUserFromRole(item.id);

      if (SERVER_RESPONSES_ENUM.RESPONSE_OK == response_recieved) {
        this.response_message = null;

        const user_profile_recieved = await getUserProfile();
        this.updateUserProfile(user_profile_recieved);
      } else {
        switch (response_recieved) {
          case SERVER_RESPONSES_ENUM.ERROR_REMOVE_LAST_ADMIN:
            this.response_message = this.$t(
              "pages_specific.profile_page.services.cannot_remove_only_admin"
            );
            break;
          default:
            // unknown error with no details provided
            this.response_message = this.$t("global.unknow_error");
            break;
        }
      }
    },

    closeAllDialogs() {
      this.initConfirmationDialogs();
    },
  },
};
</script>