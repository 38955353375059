var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"col-12 col-lg-6",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',[_c('base-subheading',[_vm._v(" "+_vm._s(_vm.$t("pages_specific.signup_page.Sign_up"))+" ")]),(!_vm.sendDataToAndroidFailed)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("pages_specific.signup_page.Automatic_signup_info_code"))+" ")]):(_vm.sendDataToAndroidFailed && _vm.registration_success)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("pages_specific.confirmation_page.user_created_ok_message"))+" ")]):_vm._e()],1),(!_vm.registration_success)?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('pages_specific.signup_page.Email_address'),"color":"dark","rules":_vm.emailRules,"required":"","hide-details":"auto","disabled":_vm.signup_request_response_is_ok_flag},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validateAndSubmit($event)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),(!_vm.signup_request_response_is_ok_flag)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"dark--text",attrs:{"width":"100%","color":"primary","loading":_vm.is_waiting_server_response_flag},on:{"click":_vm.validateAndSubmit}},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.signup_page.Request_registration"))+" ")])],1):_vm._e(),(
          _vm.signup_request_response_is_received_flag &&
            !_vm.activation_code_response_is_received_flag
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-3",class:{
            'border-color-warning': _vm.signup_request_response_is_error_flag,
            'border-color-ok': !_vm.signup_request_response_is_error_flag,
          },attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.signup_request_response_message)+" "),(_vm.show_email_help_flag && _vm.signup_request_response_is_ok_flag)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("pages_specific.signup_page.check_spam"))+" ")]):_vm._e(),(_vm.signup_request_response_is_ok_flag)?_c('v-icon',{staticClass:"float-right",attrs:{"small":""},on:{"click":function($event){_vm.show_email_help_flag = !_vm.show_email_help_flag}}},[_vm._v(" mdi-help ")]):_vm._e()],1)],1):_vm._e(),(_vm.signup_request_response_is_ok_flag)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],staticClass:"input-letter-spacing width-10em hide-spin",attrs:{"rules":[_vm.rules.required, _vm.rules.equal_6_digits],"outlined":"","label":_vm.$t('pages_specific.signup_page.Activation_code'),"color":"dark","required":"","type":"number","append-outer-icon":null,"hide-details":"auto","disabled":_vm.activation_code_response_is_ok_flag},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.verifyActivationCode($event)}},model:{value:(_vm.activation_code),callback:function ($$v) {_vm.activation_code=$$v},expression:"activation_code"}})],1),(
            !(
              _vm.activation_code_response_is_received_flag &&
              _vm.activation_code_response_is_ok_flag
            )
          )?_c('div',{staticClass:"mt-3"},[_c('v-btn',{staticClass:"dark--text mr-3 mt-2",attrs:{"color":"primary","loading":_vm.is_waiting_activation_code_response_flag},on:{"click":_vm.verifyActivationCode}},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.signup_page.verify_code"))+" ")]),_c('v-btn',{staticClass:"dark--text mr-3 mt-2",on:{"click":_vm.reinitializeResponseStatus}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-reload ")])],1)],1):_vm._e()]):_vm._e(),(_vm.activation_code_response_is_received_flag)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-card',{staticClass:"pa-3 pr-5",class:{
              'border-color-warning': _vm.activation_code_response_is_error_flag,
              'border-color-ok': !_vm.activation_code_response_is_error_flag,
            },attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.activation_code_response_message)+" ")])],1),(_vm.activation_code_response_is_ok_flag)?[_c('div',{staticClass:"mt-5"},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('global.Password'),"color":"dark","required":"","hide-details":"auto","append-icon":_vm.show_password_input ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show_password_input ? 'text' : 'password',"rules":[_vm.rules.required, _vm.rules.min]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.activateUser($event)},"click:append":function($event){_vm.show_password_input = !_vm.show_password_input}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"mt-3"},[_c('v-btn',{staticClass:"dark--text mr-3 mt-2",attrs:{"color":"primary","loading":_vm.is_waiting_activation_code_response_flag},on:{"click":_vm.activateUser}},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.signup_page.Activate_my_account"))+" ")]),_c('v-btn',{staticClass:"dark--text mr-3 mt-2",on:{"click":_vm.reinitializeResponseStatus}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-reload ")])],1)],1)]:_vm._e()],2):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("pages_specific.signup_page.accept_terms_by_creating_part1"))+" "),_c('router-link',{staticStyle:{"color":"inherit"},attrs:{"to":"/terms"}},[_vm._v(_vm._s(_vm.$t("global.the_terms")))]),_vm._v(" "+_vm._s(_vm.$t("global.and_with_spaces"))+" "),_c('router-link',{staticStyle:{"color":"inherit"},attrs:{"to":"/policy"}},[_vm._v(_vm._s(_vm.$t("global.the_ploicy")))]),_vm._v(". ")],1)])]:_vm._e(),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"6"}},[_c('info-text',{attrs:{"text":_vm.$t('global.Already_have_account')}}),_c('v-btn',{staticClass:"mt-2",attrs:{"width":"100%","href":_vm.loginUrl}},[_vm._v(" "+_vm._s(_vm.$t("global.Sign_in"))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }