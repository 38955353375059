var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    null != _vm.user_teams_in_which_he_is_admin &&
    _vm.user_teams_in_which_he_is_admin.length > 0
  )?_c('v-card',{staticClass:"pa-5 mt-10",attrs:{"width":"100%"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('info-text',{staticClass:"ma-2 mb-5",attrs:{"text":_vm.$t('pages_specific.profile_page.invitations.invitations_title')}}),_c('v-col',{attrs:{"cols":"12"}},[(
            null != _vm.invitation_requests_waiting &&
            _vm.invitation_requests_waiting.length > 0
          )?_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.invitations.Requests_in_progress" ))+" ")]),_c('ul',{staticClass:"invitations"},_vm._l((_vm.invitation_requests_waiting),function(item,i){return _c('li',{key:i,class:{
                accepted: true == item.accepted,
                declined: false == item.accepted,
              }},[_c('info-text',{attrs:{"text":_vm.$t('global.to') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s(item.receiver_email)+" "),_c('info-text',{staticClass:"ml-4",attrs:{"text":_vm.$t('global.team') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s(item.team_name)+" "),_c('info-text',{staticClass:"ml-4",attrs:{"text":_vm.$t('global.service') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s("TUS" == item.service_name ? "PUCS" : item.service_name)+" "),_c('info-text',{staticClass:"ml-4",attrs:{"text":_vm.$t('global.role') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s(_vm.role_name(item.role_name))+" "),_c('info-text',{staticClass:"ml-4",attrs:{"text":_vm.$t('global.date') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" "),(null == item.accepted)?_c('v-dialog',{attrs:{"max-width":"600","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var dialog = ref.on;
              var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"elevation":"2"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"color":"dark"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.invitations.remove_request_short" )))])])]}}],null,true),model:{value:(_vm.dialogs[i]),callback:function ($$v) {_vm.$set(_vm.dialogs, i, $$v)},expression:"dialogs[i]"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.invitations.are_you_sure_to_remove_request" ))+" ")]),_c('v-card-text',[_c('info-text',{attrs:{"text":_vm.$t('global.to') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s(item.receiver_email)+" "),_c('info-text',{staticClass:"ml-4",attrs:{"text":_vm.$t('global.team') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s(item.team_name)+" ")],1),_c('div',{staticClass:"pt-1 pb-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",on:{"click":function($event){_vm.closeAllDialogs();
                        _vm.response_message = null;}}},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.invitations.keep_request" ))+" ")]),_c('v-btn',{staticClass:"mx-2 dark--text",attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteOngoingInvitation(item)}}},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.invitations.yes_delete" ))+" ")])],1)],1)],1):_vm._e()],1)}),0)]):_vm._e()])],1),(!_vm.editing_mode_flag)?_c('v-row',{staticClass:"mx-1 mb-3"},[_c('v-btn',{staticClass:"dark--text",attrs:{"color":"primary"},on:{"click":function($event){_vm.editing_mode_flag = true}}},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.profile_page.invitations.invite_user_team"))+" ")])],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.profile_page.invitations.invite_user_team"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"rules":[
            function (v) { return !!v ||
              _vm.$t('form_validation.please_enter') + _vm.$t('global.the_team'); } ],"hide-details":"auto","items":_vm.user_teams_in_which_he_is_admin_with_title,"label":"Select a team","outlined":"","item-text":"title","item-value":"team","return-object":""},model:{value:(_vm.selected_team),callback:function ($$v) {_vm.selected_team=$$v},expression:"selected_team"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"rules":[
            function (v) { return !!v ||
              _vm.$t('form_validation.please_enter') + _vm.$t('global.the_role'); } ],"required":"","hide-details":"auto","items":_vm.roles,"label":"Select a role","outlined":"","item-text":"role_title","item-value":"role_enum"},model:{value:(_vm.selected_role),callback:function ($$v) {_vm.selected_role=$$v},expression:"selected_role"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('custom-input',{attrs:{"label":_vm.$t('pages_specific.signup_page.Email_address'),"editing_mode_flag":true,"rules":_vm.emailRules},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validateAndSubmit($event)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),(_vm.response_is_received_flag)?_c('v-card',{staticClass:"pa-3 mb-1 mt-5 mx-3",class:{
          'border-color-warning': _vm.response_is_error_flag,
          'border-color-ok': !_vm.response_is_error_flag,
        },attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.response_message)+" "),_c('v-icon',{staticClass:"float-right",on:{"click":function($event){return _vm.reinitializeResponseStatus()}}},[_vm._v(" mdi-close ")])],1):_vm._e(),_c('v-row',{staticClass:"mt-5 mb-3 mx-1"},[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-btn',{staticClass:"dark--text",attrs:{"width":"100%","color":"primary","loading":_vm.is_waiting_server_response_flag},on:{"click":_vm.validateAndSubmit}},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.profile_page.invitations.Invite_the_user"))+" ")])],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-btn',{attrs:{"width":"100%","loading":false},on:{"click":function($event){_vm.editing_mode_flag = false}}},[_vm._v(" "+_vm._s(_vm.$t("global.Cancel"))+" ")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }