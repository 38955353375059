<template>
  <v-card
    width="100%"
    class="pa-5 mt-10"
    v-if="
      null != user_teams_in_which_he_is_admin &&
      user_teams_in_which_he_is_admin.length > 0
    "
  >
    <v-form ref="form" lazy-validation>
      <v-row>
        <info-text
          :text="
            $t('pages_specific.profile_page.invitations.invitations_title')
          "
          class="ma-2 mb-5"
        />

        <v-col cols="12">
          <div
            v-if="
              null != invitation_requests_waiting &&
              invitation_requests_waiting.length > 0
            "
            class="mb-5"
          >
            <div class="mb-2">
              {{
                $t(
                  "pages_specific.profile_page.invitations.Requests_in_progress"
                )
              }}
            </div>
            <ul class="invitations">
              <li
                v-for="(item, i) in invitation_requests_waiting"
                :key="i"
                :class="{
                  accepted: true == item.accepted,
                  declined: false == item.accepted,
                }"
              >
                <info-text :text="$t('global.to') + $t('global.colon')" />
                {{ item.receiver_email }}
                <info-text
                  class="ml-4"
                  :text="$t('global.team') + $t('global.colon')"
                />
                {{ item.team_name }}
                <info-text
                  class="ml-4"
                  :text="$t('global.service') + $t('global.colon')"
                />
                {{ "TUS" == item.service_name ? "PUCS" : item.service_name }}
                <info-text
                  class="ml-4"
                  :text="$t('global.role') + $t('global.colon')"
                />
                {{ role_name(item.role_name) }}
                <info-text
                  class="ml-4"
                  :text="$t('global.date') + $t('global.colon')"
                />
                {{ formatDate(item.date) }}

                <v-dialog
                  v-if="null == item.accepted"
                  v-model="dialogs[i]"
                  max-width="600"
                  :retain-focus="false"
                >
                  <template v-slot:activator="{ on: dialog, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          elevation="2"
                          class="ml-4"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...dialog }"
                        >
                          <v-icon color="dark">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          $t(
                            "pages_specific.profile_page.invitations.remove_request_short"
                          )
                        }}</span
                      >
                    </v-tooltip>
                  </template>
                  <v-card>
                    <v-card-title class="text-h6">
                      {{
                        $t(
                          "pages_specific.profile_page.invitations.are_you_sure_to_remove_request"
                        )
                      }}
                    </v-card-title>

                    <v-card-text>
                      <info-text :text="$t('global.to') + $t('global.colon')" />
                      {{ item.receiver_email }}
                      <info-text
                        class="ml-4"
                        :text="$t('global.team') + $t('global.colon')"
                      />
                      {{ item.team_name }}
                    </v-card-text>

                    <div class="pt-1 pb-3">
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="
                          closeAllDialogs();
                          response_message = null;
                        "
                        class="mx-2"
                      >
                        {{
                          $t(
                            "pages_specific.profile_page.invitations.keep_request"
                          )
                        }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mx-2 dark--text"
                        @click="deleteOngoingInvitation(item)"
                      >
                        {{
                          $t(
                            "pages_specific.profile_page.invitations.yes_delete"
                          )
                        }}
                      </v-btn>
                    </div>
                  </v-card>
                </v-dialog>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="!editing_mode_flag" class="mx-1 mb-3">
        <v-btn
          @click="editing_mode_flag = true"
          color="primary"
          class="dark--text"
        >
          {{ $t("pages_specific.profile_page.invitations.invite_user_team") }}
        </v-btn>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <div class="mb-2">
            {{ $t("pages_specific.profile_page.invitations.invite_user_team") }}
          </div>
        </v-col>

        <v-col cols="12">
          <v-select
            :rules="[
              (v) =>
                !!v ||
                $t('form_validation.please_enter') + $t('global.the_team'),
            ]"
            hide-details="auto"
            :items="user_teams_in_which_he_is_admin_with_title"
            label="Select a team"
            outlined
            item-text="title"
            item-value="team"
            return-object
            v-model="selected_team"
          />
        </v-col>

        <v-col cols="12">
          <v-select
            :rules="[
              (v) =>
                !!v ||
                $t('form_validation.please_enter') + $t('global.the_role'),
            ]"
            required
            hide-details="auto"
            :items="roles"
            label="Select a role"
            outlined
            item-text="role_title"
            item-value="role_enum"
            v-model="selected_role"
          />
        </v-col>

        <!-- Email input form  -->
        <v-col cols="12">
          <custom-input
            v-model="email"
            :label="$t('pages_specific.signup_page.Email_address')"
            :editing_mode_flag="true"
            :rules="emailRules"
            @keydown.enter="validateAndSubmit"
          />
        </v-col>

        <!-- Show response  -->
        <v-card
          v-if="response_is_received_flag"
          outlined
          :class="{
            'border-color-warning': response_is_error_flag,
            'border-color-ok': !response_is_error_flag,
          }"
          class="pa-3 mb-1 mt-5 mx-3"
        >
          {{ response_message }}

          <v-icon class="float-right" @click="reinitializeResponseStatus()">
            mdi-close
          </v-icon>
        </v-card>

        <!-- Send confirmation email button  -->
        <v-row class="mt-5 mb-3 mx-1">
          <v-col md="6" cols="12">
            <v-btn
              width="100%"
              color="primary"
              class="dark--text"
              @click="validateAndSubmit"
              :loading="is_waiting_server_response_flag"
            >
              {{
                $t("pages_specific.profile_page.invitations.Invite_the_user")
              }}
            </v-btn>
          </v-col>
          <v-col md="6" cols="12">
            <v-btn
              width="100%"
              @click="editing_mode_flag = false"
              :loading="false"
            >
              {{ $t("global.Cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import {
  deleteInvitation,
  getInvitationRequestsSent,
  PREDEFINED_ROLES_ENUM,
  sendRequestTeamInvitation,
} from "../../utils/ServerCallsUtils";
import InfoText from "../core/InfoText.vue";
import { mapGetters } from "vuex";
import { email_regex } from "../../utils/RegexUtils";
import CustomInput from "../custom_styling/CustomInput.vue";
import {
  isResponseError,
  isResponseReceived,
  isWaitingServerResponse,
  SERVER_RESPONSES_ENUM,
} from "../../utils/ServerResponsesUtils";

export default {
  components: { InfoText, CustomInput },

  data() {
    return {
      invitation_requests_sent: [],
      selected_team: null,
      email: "",
      selected_role: null,
      editing_mode_flag: false,
      dialogs: [],

      invite_user_response_status: SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET,
    };
  },

  async created() {
    this.reloadInvitationRequestsSent();
  },

  mounted() {
    this.initConfirmationDialogs();
  },

  watch: {
    invitation_requests_sent() {
      this.initConfirmationDialogs();
    },

    "$store.state.data_is_updated_toggle": function () {
      // reload the component's data
      this.reloadInvitationRequestsSent();
    },
  },

  computed: {
    ...mapGetters(["user_teams_in_which_he_is_admin"]),

    invitation_requests_waiting() {
      var invitation_requests_only_waiting = [];
      for (const request of this.invitation_requests_sent) {
        if (null == request.accepted)
          invitation_requests_only_waiting.push(request);
      }
      return invitation_requests_only_waiting;
    },

    user_teams_in_which_he_is_admin_with_title() {
      var titles = [];

      if (null != this.user_teams_in_which_he_is_admin) {
        for (let i = 0; i < this.user_teams_in_which_he_is_admin.length; i++) {
          const team = this.user_teams_in_which_he_is_admin[i];
          const title =
            this.$t("global.team") +
            " '" +
            team.team.name +
            "'" +
            this.$t("pages_specific.profile_page.invitations.in_the_service") +
            "'" +
            ("TUS" == team.team.service.name
              ? "PUCS"
              : team.team.service.name) +
            "'";

          titles.push({ title: title, team: team });
        }
      }

      return titles;
    },

    roles() {
      var available_roles = [
        {
          role_enum: PREDEFINED_ROLES_ENUM.USER,
          role_title: this.role_name(PREDEFINED_ROLES_ENUM.USER),
        },
        {
          role_enum: PREDEFINED_ROLES_ENUM.ADMIN,
          role_title: this.role_name(PREDEFINED_ROLES_ENUM.ADMIN),
        },
      ];
      return available_roles;
    },

    emailRules() {
      return [
        (v) =>
          !!v ||
          this.$t("form_validation.please_enter") + this.$t("global.the_email"),
        (v) => email_regex.test(v) || this.$t("form_validation.mail_valid"),
      ];
    },

    is_waiting_server_response_flag() {
      return isWaitingServerResponse(this.invite_user_response_status);
    },

    response_is_received_flag() {
      return isResponseReceived(this.invite_user_response_status);
    },

    response_is_error_flag() {
      return isResponseError(this.invite_user_response_status);
    },
  },

  methods: {
    async reloadInvitationRequestsSent() {
      this.invitation_requests_sent = await getInvitationRequestsSent();
    },

    initConfirmationDialogs() {
      this.dialogs = [];
      for (let i = 0; i < this.invitation_requests_sent.length; i++) {
        this.dialogs.push(false);
      }
    },

    closeAllDialogs() {
      this.initConfirmationDialogs();
    },

    async validateAndSubmit(event) {
      event.preventDefault();

      if (this.validate()) {
        await this.send_request_team_invitation();
      }
    },

    validate() {
      return this.$refs.form.validate();
    },

    async send_request_team_invitation() {
      if (
        null != this.selected_team &&
        null != this.selected_team.team &&
        null != this.selected_team.team.team.service
      ) {
        this.invite_user_response_status =
          SERVER_RESPONSES_ENUM.WAITING_RESPONSE;

        const response_with_status = await sendRequestTeamInvitation(
          this.selected_team.team.team.service.name,
          this.selected_team.team.team.name,
          this.selected_role,
          this.email
        );

        this.invite_user_response_status = response_with_status.response_status;
        this.response_message = this.getMessageFromResponseCode();

        if (
          SERVER_RESPONSES_ENUM.RESPONSE_OK == this.invite_user_response_status
        ) {
          this.editing_mode_flag = false;

          // reload the invitations list
          this.reloadInvitationRequestsSent();
        }
      }
    },

    role_name(role_name) {
      switch (role_name) {
        case PREDEFINED_ROLES_ENUM.ADMIN:
          return this.$t("global.roles.admin");
        case PREDEFINED_ROLES_ENUM.OWNER:
          return this.$t("global.roles.owner");
        case PREDEFINED_ROLES_ENUM.USER:
          return this.$t("global.roles.user");
        default:
          return role_name;
      }
    },

    getMessageFromResponseCode() {
      switch (this.invite_user_response_status) {
        case SERVER_RESPONSES_ENUM.RESPONSE_OK:
          return this.$t(
            "pages_specific.profile_page.invitations.user_invited"
          );
        case SERVER_RESPONSES_ENUM.ERROR_DOES_NOT_EXIST:
          return this.$t(
            "pages_specific.profile_page.invitations.user_does_not_exist"
          );
        case SERVER_RESPONSES_ENUM.ERROR_ALREADY_EXIST:
          return this.$t(
            "pages_specific.profile_page.invitations.request_already_exist"
          );
        case SERVER_RESPONSES_ENUM.ERROR_INVITATION_TO_YOURSELF:
          return this.$t(
            "pages_specific.profile_page.invitations.cannot_send_invitation_to_yourself"
          );
        case SERVER_RESPONSES_ENUM.ERROR_USER_ALREADY_IN_TEAM:
          return this.$t(
            "pages_specific.profile_page.invitations.user_already_in_team"
          );
        case SERVER_RESPONSES_ENUM.ERROR_USER_ALREADY_INVITED_IN_TEAM:
          return this.$t(
            "pages_specific.profile_page.invitations.user_already_invited_in_team"
          );
        default:
          // unknown error with no details provided
          return this.$t("global.unknow_error");
      }
    },

    async deleteOngoingInvitation(item) {
      this.dialog = false;

      await deleteInvitation(item.id);
      this.reloadInvitationRequestsSent();
    },

    reinitializeResponseStatus() {
      this.invite_user_response_status =
        SERVER_RESPONSES_ENUM.REQUEST_NOT_SEND_YET;
    },

    formatDate: function (object_date) {
      var d = Date.parse(object_date);
      return new Date(d).toLocaleDateString();
    },
  },
};
</script>
