<template>
  <v-card width="100%" class="pa-5 mt-10">
    <v-form ref="form" lazy-validation>
      <v-row>
        <info-text
          :text="$t('pages_specific.profile_page.history.history_title')"
          class="ma-2 mb-5"
        />
      </v-row>

      <v-row>
        <ul class="invitations">
          <li v-for="(item, i) in history" :key="i">
            <div>
              <v-icon small :color="getEventIconColor(item)">
                {{ getEventIcon(item) }}
              </v-icon>

              <info-text
                class="ml-4"
                :text="$t('global.date') + $t('global.colon')"
              />
              {{ formatDate(item.history_date) }}
            </div>

            <div class="mt-2">
              <info-text :text="$t('global.event') + $t('global.colon')" />
              <span>
                {{ getEventTextMainPart(item) }}
              </span>

              <br />
              <span v-if="null != item.history_user_id__username">
                {{ getEventTextSecondPart(item) }}
              </span>
            </div>
          </li>
        </ul>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import InfoText from "../core/InfoText.vue";
import { mapGetters } from "vuex";
import {
  getHistoryTeam,
  HISTORY_EVENTS_TYPES_ENUM,
  PREDEFINED_ROLES_ENUM,
} from "../../utils/ServerCallsUtils";

export default {
  components: { InfoText },

  data() {
    return {
      history: [],
    };
  },

  async created() {
    this.reloadHistory();
  },

  watch: {
    "$store.state.data_is_updated_toggle": function () {
      // reload the component's data
      this.reloadHistory();
    },
  },

  computed: {
    ...mapGetters(["user_teams_in_which_he_is_admin"]),

    roles() {
      var available_roles = [
        {
          role_enum: PREDEFINED_ROLES_ENUM.USER,
          role_title: this.role_name(PREDEFINED_ROLES_ENUM.USER),
        },
        {
          role_enum: PREDEFINED_ROLES_ENUM.ADMIN,
          role_title: this.role_name(PREDEFINED_ROLES_ENUM.ADMIN),
        },
      ];
      return available_roles;
    },
  },

  methods: {
    async reloadHistory() {
      this.history = await getHistoryTeam();
    },

    role_name(role_name) {
      switch (role_name) {
        case PREDEFINED_ROLES_ENUM.ADMIN:
          return this.$t("global.roles.admin");
        case PREDEFINED_ROLES_ENUM.OWNER:
          return this.$t("global.roles.owner");
        case PREDEFINED_ROLES_ENUM.USER:
          return this.$t("global.roles.user");
        default:
          return role_name;
      }
    },

    formatDate: function (object_date) {
      var d = Date.parse(object_date);
      return new Date(d).toLocaleDateString();
    },

    getEventIcon(item) {
      switch (item.history_type) {
        case HISTORY_EVENTS_TYPES_ENUM.CREATE:
          return "mdi-account-plus";

        case HISTORY_EVENTS_TYPES_ENUM.REMOVE:
          return "mdi-account-remove";

        case HISTORY_EVENTS_TYPES_ENUM.CHANGE:
          if (PREDEFINED_ROLES_ENUM.ADMIN == item.role) {
            return "mdi-stairs-up";
          } else {
            return "mdi-stairs-down";
          }
        default:
          return "mdi-crosshairs-question";
      }
    },

    getEventIconColor(item) {
      switch (item.history_type) {
        case HISTORY_EVENTS_TYPES_ENUM.CREATE:
          return "green";

        case HISTORY_EVENTS_TYPES_ENUM.REMOVE:
          return "red";

        case HISTORY_EVENTS_TYPES_ENUM.CHANGE:
          if (PREDEFINED_ROLES_ENUM.ADMIN == item.role) {
            return "green";
          } else {
            return "warning";
          }

        default:
          return "gray";
      }
    },

    getEventTextMainPart(item) {
      if ("TUS" == item.team__service__name) {
        item.team__service__name = "PUCS";
      }

      switch (item.history_type) {
        case HISTORY_EVENTS_TYPES_ENUM.CREATE:
          return this.$t("pages_specific.profile_page.history.user_added", {
            user: item.user__username,
            team: item.team__name,
            service: item.team__service__name,
            role: this.role_name(item.role).toLowerCase(),
          });

        case HISTORY_EVENTS_TYPES_ENUM.REMOVE:
          return this.$t("pages_specific.profile_page.history.user_removed", {
            user: item.user__username,
            team: item.team__name,
            service: item.team__service__name,
          });

        case HISTORY_EVENTS_TYPES_ENUM.CHANGE:
          if (PREDEFINED_ROLES_ENUM.ADMIN == item.role) {
            return this.$t(
              "pages_specific.profile_page.history.user_promoted",
              {
                user: item.user__username,
                team: item.team__name,
                service: item.team__service__name,
              }
            );
          } else {
            return this.$t(
              "pages_specific.profile_page.history.user_downgraded",
              {
                user: item.user__username,
                team: item.team__name,
                service: item.team__service__name,
              }
            );
          }

        default:
          return this.$t("pages_specific.profile_page.history.unknown_event");
      }
    },

    getEventTextSecondPart(item) {
      switch (item.history_type) {
        case HISTORY_EVENTS_TYPES_ENUM.CREATE:
          return (
            " " +
            this.$t(
              "pages_specific.profile_page.history.recieved_invitation_from",
              {
                user_issued_invitation: item.history_user_id__username,
              }
            )
          );

        case HISTORY_EVENTS_TYPES_ENUM.REMOVE:
        case HISTORY_EVENTS_TYPES_ENUM.CHANGE:
          return (
            " " +
            this.$t(
              "pages_specific.profile_page.history.user_performed_action",
              {
                user_performed: item.history_user_id__username,
              }
            )
          );

        default:
          return "";
      }
    },
  },
};
</script>