<template>
  <v-container class="pb-15 col-12 col-lg-6">
    <v-btn :to="homeUrl" class="mb-10">
      {{ $t("global.go_mail_page") }}
    </v-btn>

    <!-- Privacy policy -->
    <terms-or-policy-from-locals
      v-if="isPrivacyPolicy"
      :path_in_translations="'pages_specific.privacy'"
    />

    <!-- Terms of Service -->
    <terms-or-policy-from-locals
      v-else
      :path_in_translations="'pages_specific.terms'"
    />

    <div class="mt-15">
      <p v-if="isPrivacyPolicy">
        {{ $t("pages_specific.privacy.see_also") }}
        <router-link style="color: inherit" to="/terms">{{
          $t("global.terms_of_service")
        }}</router-link
        >.
      </p>
      <p v-else>
        {{ $t("pages_specific.terms.see_also") }}
        <router-link style="color: inherit" to="/policy">{{
          $t("global.privacy_policy")
        }}</router-link
        >.
      </p>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ContactForm from "../components/app_specific/ContactForm.vue";
import TermsOrPolicyFromLocals from "../components/app_specific/TermsOrPolicyFromLocals.vue";

export default {
  components: { ContactForm, TermsOrPolicyFromLocals },

  props: {
    isPrivacyPolicy: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(["is_carnaval_service"]),

    homeUrl() {
      if (this.is_carnaval_service) {
        return "/?service=CARNAVAL";
      } else {
        return "/";
      }
    },
  },

  data() {
    return {
      contact_form_is_opened: false,
    };
  },
};
</script>