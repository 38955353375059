var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5 mt-10",attrs:{"width":"100%"}},[_c('v-row',[_c('info-text',{staticClass:"ma-2 mb-5",attrs:{"text":_vm.$t('pages_specific.profile_page.services.Your_services_and_teams')}}),(
        null != _vm.user_profile &&
        null != _vm.user_profile.json_body &&
        null != _vm.user_profile.json_body.roles_in_teams &&
        _vm.user_profile.json_body.roles_in_teams.length > 0
      )?_c('v-col',{attrs:{"cols":"12"}},[(null != _vm.response_message)?_c('v-card',{staticClass:"border-color-warning pa-3 mb-10",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.response_message)+" "),_c('v-icon',{staticClass:"float-right",on:{"click":function($event){_vm.response_message = null}}},[_vm._v(" mdi-close ")])],1):_vm._e(),_c('ul',{staticClass:"invitations"},_vm._l((_vm.user_profile.json_body.roles_in_teams),function(item,i){return _c('li',{key:i},[_c('info-text',{attrs:{"text":_vm.$t('global.service') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s("TUS" == item.team.service.name ? "PUCS" : item.team.service.name)+" "),_c('info-text',{staticClass:"ml-4",attrs:{"text":_vm.$t('global.team') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s(item.team.name)+" "),_c('info-text',{staticClass:"ml-4",attrs:{"text":_vm.$t('global.role') + _vm.$t('global.colon')}}),_vm._v(" "+_vm._s(_vm.role_name(item))+" "),_c('v-dialog',{attrs:{"max-width":"600","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var dialog = ref.on;
      var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"elevation":"2"}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"color":"dark"}},[_vm._v("mdi-exit-run")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.services.delete_from_role_short" )))])])]}}],null,true),model:{value:(_vm.dialogs[i]),callback:function ($$v) {_vm.$set(_vm.dialogs, i, $$v)},expression:"dialogs[i]"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.profile_page.services.are_you_sure_to_remove_role", { team: item.team.name } ))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("global.action_cannot_be_undone")))]),_c('div',{staticClass:"pt-1 pb-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",on:{"click":_vm.closeAllDialogs}},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.profile_page.services.keep_role"))+" ")]),_c('v-btn',{staticClass:"mx-2 dark--text",attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteUserRole(item, i)}}},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.profile_page.services.yes_delete"))+" ")])],1)],1)],1)],1)}),0)],1):_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.profile_page.services.not_registred_for_services"))+" ")])],1),_c('invitation-recieved',{on:{"anInvitationIsAccepted":function($event){return _vm.anInvitationIsAcceptedEvent($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }