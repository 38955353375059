<template>
  <v-container class="pb-15 col-12 col-lg-6">
    <v-btn :to="homeUrl" class="mb-10">
      {{ $t("global.go_mail_page") }}
    </v-btn>

    <base-subheading class="mb-5">{{ $t("global.FAQ") }}</base-subheading>

    <div
      class="mt-5"
      v-for="(item, i) in $t('pages_specific.faq.questions')"
      :key="i"
    >
      <p class="text-h5 font-weight-medium">
        {{ $t("pages_specific.faq.questions." + i + ".question") }}
      </p>
      <p>
        {{ $t("pages_specific.faq.questions." + i + ".answer") }}
      </p>
    </div>

    <div class="mt-10">
      <p class="font-italic text-h6">
        {{ $t("pages_specific.faq.still_have_questions") }}
      </p>
      <v-btn
        :color="contact_form_is_opened ? null : 'primary'"
        class="dark--text"
        @click="contact_form_is_opened = true"
        >{{ $t("pages_specific.faq.contact_us") }}</v-btn
      >
    </div>

    <v-card class="pa-5" v-if="contact_form_is_opened">
      <v-icon class="float-right" @click="contact_form_is_opened = false"
        >mdi-close</v-icon
      >
      <contact-form class="mt-5" />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ContactForm from "../components/app_specific/ContactForm.vue";
export default {
  components: { ContactForm },

  computed: {
    ...mapGetters(["is_carnaval_service"]),

    homeUrl() {
      if (this.is_carnaval_service) {
        return "/?service=CARNAVAL";
      } else {
        return "/";
      }
    },
  },

  data() {
    return {
      contact_form_is_opened: true,
    };
  },
};
</script>