<template>
  <v-card
    width="100%"
    class="pa-5 mt-10"
    v-if="
      null != user_teams_in_which_he_is_admin &&
        user_teams_in_which_he_is_admin.length > 0
    "
  >
    <v-row>
      <info-text
        :text="
          $t('pages_specific.profile_page.users_in_teams.Users_in_your_teams')
        "
        class="ma-2 mb-5"
      />

      <wait-server-progress v-if="!response_is_recieved" />

      <v-col cols="12" v-else>
        <div v-if="users_in_teams_grouped_and_filtered.length > 0">
          <ul class="invitations">
            <li
              v-for="(service, i) in users_in_teams_grouped_and_filtered"
              :key="i"
            >
              <info-text :text="$t('global.service') + $t('global.colon')" />
              {{
                "TUS" == service.service_name ? "PUCS" : service.service_name
              }}

              <ul>
                <li
                  class="padding-0"
                  v-for="(team, j) in service.teams_and_users"
                  :key="j"
                >
                  <info-text :text="$t('global.team') + $t('global.colon')" />
                  {{ team.team_name }}

                  <ul>
                    <li
                      v-for="(item, k) in team.users_and_roles"
                      :key="k"
                      class="padding-0"
                    >
                      <info-text
                        class="ml-4"
                        :text="$t('global.user') + $t('global.colon')"
                      />
                      {{ item.user_name }}

                      <info-text
                        class="ml-4"
                        :text="$t('global.role') + $t('global.colon')"
                      />
                      {{ role_name(item.role_name) }}

                      <v-dialog
                        v-model="dialogs_confirm_change_role[item.count_i]"
                        max-width="600"
                        :retain-focus="false"
                      >
                        <template v-slot:activator="{ on: dialog, attrs }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                              <v-btn
                                elevation="2"
                                class="ml-4"
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...dialog }"
                              >
                                <v-icon color="dark">{{
                                  isAdminUser(item)
                                    ? "mdi-stairs-down"
                                    : "mdi-stairs-up"
                                }}</v-icon>
                              </v-btn>
                            </template>
                            <span>
                              {{
                                isAdminUser(item)
                                  ? $t(
                                      "pages_specific.profile_page.users_in_teams.downgrade_admin_short"
                                    )
                                  : $t(
                                      "pages_specific.profile_page.users_in_teams.promote_user_short"
                                    )
                              }}
                            </span>
                          </v-tooltip>
                        </template>

                        <v-card>
                          <v-card-title class="text-h6">
                            {{
                              isAdminUser(item)
                                ? $t(
                                    "pages_specific.profile_page.users_in_teams.are_you_sure_to_downgrade_admin",
                                    {
                                      user: item.user_name,
                                      team: team.team_name,
                                    }
                                  )
                                : $t(
                                    "pages_specific.profile_page.users_in_teams.are_you_sure_to_promote_user",
                                    {
                                      user: item.user_name,
                                      team: team.team_name,
                                    }
                                  )
                            }}
                          </v-card-title>

                          <v-card-text>
                            {{
                              $t("global.action_cannot_be_undone")
                            }}</v-card-text
                          >

                          <div class="pt-1 pb-3">
                            <v-spacer></v-spacer>
                            <v-btn @click="closeAllDialogs" class="mx-2">
                              {{
                                $t(
                                  "pages_specific.profile_page.users_in_teams.keep_role"
                                )
                              }}
                            </v-btn>
                            <v-btn
                              color="primary"
                              class="mx-2 dark--text"
                              @click="changeUserRole(item)"
                            >
                              {{
                                isAdminUser(item)
                                  ? $t(
                                      "pages_specific.profile_page.users_in_teams.yes_downgrade_admin"
                                    )
                                  : $t(
                                      "pages_specific.profile_page.users_in_teams.yes_promote_user"
                                    )
                              }}
                            </v-btn>
                          </div>
                        </v-card>
                      </v-dialog>

                      <v-dialog
                        v-model="dialogs_confirm_remove_user[item.count_i]"
                        max-width="600"
                        :retain-focus="false"
                      >
                        <template v-slot:activator="{ on: dialog, attrs }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                              <v-btn
                                elevation="2"
                                class="ml-4"
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...dialog }"
                              >
                                <v-icon color="dark"
                                  >mdi-account-remove-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>
                              {{
                                $t(
                                  "pages_specific.profile_page.users_in_teams.remove_role_short"
                                )
                              }}</span
                            >
                          </v-tooltip>
                        </template>

                        <v-card>
                          <v-card-title class="text-h6">
                            {{
                              $t(
                                "pages_specific.profile_page.users_in_teams.are_you_sure_to_remove_role",
                                {
                                  user: item.user_name,
                                  team: team.team_name,
                                }
                              )
                            }}
                          </v-card-title>

                          <v-card-text>
                            {{
                              $t("global.action_cannot_be_undone")
                            }}</v-card-text
                          >

                          <div class="pt-1 pb-3">
                            <v-spacer></v-spacer>
                            <v-btn @click="closeAllDialogs" class="mx-2">
                              {{
                                $t(
                                  "pages_specific.profile_page.users_in_teams.keep_role"
                                )
                              }}
                            </v-btn>
                            <v-btn
                              color="primary"
                              class="mx-2 dark--text"
                              @click="deleteUserRole(item, i)"
                            >
                              {{
                                $t(
                                  "pages_specific.profile_page.users_in_teams.yes_delete"
                                )
                              }}
                            </v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-else>
          {{ $t("pages_specific.profile_page.users_in_teams.no_other_users") }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import InfoText from "../core/InfoText.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import {
  changeUserRoleApi,
  deleteUserFromRole,
  getUsersInTeams,
} from "../../utils/ServerCallsUtils";
import WaitServerProgress from "./WaitServerProgress.vue";

function filterUsersInTeams(users_in_teams_original, current_user_profile) {
  var users_in_teams_filtered = [];

  for (const user of users_in_teams_original) {
    if (
      user.user &&
      current_user_profile.json_body &&
      user.user.username != current_user_profile.json_body.username
    ) {
      users_in_teams_filtered.push(user);
    }
  }

  return users_in_teams_filtered;
}

function rearangeUsersByServicesAndTeams(users_in_teams_filtered) {
  const users_grouped_by_services = groupUsersByServices(
    users_in_teams_filtered
  );
  const users_grouped_by_services_and_teams = groupUsersGroupedByServicesByTeams(
    users_grouped_by_services
  );

  return users_grouped_by_services_and_teams;
}

function groupUsersByServices(users_in_teams_filtered) {
  const users_in_teams_sorted = users_in_teams_filtered.sort(sortByServiceName);

  var users_grouped_by_services = [];

  var last_service_name = "";
  var services_count = 0;

  for (let i = 0; i < users_in_teams_sorted.length; i++) {
    const user = users_in_teams_sorted[i];
    const team_user_role_object = {
      id: user.id,
      team_name: user.team.name,
      user_name: user.user.username,
      role_name: user.role.name,
      count_i: i,
    };

    if (last_service_name != user.team.service.name) {
      last_service_name = user.team.service.name;

      // start a new service group
      users_grouped_by_services.push({
        service_name: last_service_name,
        teams_and_users: [team_user_role_object],
      });
      services_count += 1;
    } else {
      // add a user to the previous service group
      users_grouped_by_services[services_count - 1].teams_and_users.push(
        team_user_role_object
      );
    }
  }

  return users_grouped_by_services;
}

function groupUsersGroupedByServicesByTeams(users_grouped_by_services) {
  var users_grouped_by_services_and_teams = [];

  for (const service_group of users_grouped_by_services) {
    const service_teams_and_users = service_group.teams_and_users.sort(
      sortByTeamName
    );

    var users_grouped_by_teams = [];

    var last_team_name = "";
    var teams_count = 0;

    for (const team_user of service_teams_and_users) {
      const user_role_object = {
        id: team_user.id,
        user_name: team_user.user_name,
        role_name: team_user.role_name,
        count_i: team_user.count_i,
      };

      if (last_team_name != team_user.team_name) {
        last_team_name = team_user.team_name;

        // start a new team group
        users_grouped_by_teams.push({
          team_name: last_team_name,
          users_and_roles: [user_role_object],
        });
        teams_count += 1;
      } else {
        // add a user to the previous service group
        users_grouped_by_teams[teams_count - 1].users_and_roles.push(
          user_role_object
        );
      }
    }

    users_grouped_by_services_and_teams.push({
      service_name: service_group.service_name,
      teams_and_users: users_grouped_by_teams,
    });
  }

  return users_grouped_by_services_and_teams;
}

function sortByServiceName(a, b) {
  var service_name_a = a.team.service.name;
  var service_name_b = b.team.service.name;
  if (service_name_a < service_name_b) {
    return -1;
  }
  if (service_name_a > service_name_b) {
    return 1;
  }
  return 0;
}

function sortByTeamName(a, b) {
  var team_name_a = a.team_name;
  var team_name_b = b.team_name;
  if (team_name_a < team_name_b) {
    return -1;
  }
  if (team_name_a > team_name_b) {
    return 1;
  }
  return 0;
}

export const PREDEFINED_ROLES_ENUM = Object.freeze({
  ADMIN: "admin",
  OWNER: "owner",
  USER: "user",
});

export default {
  components: { InfoText, WaitServerProgress },

  data() {
    return {
      response_is_recieved: false,
      users_in_teams_grouped_and_filtered: [],
      dialogs_confirm_remove_user: [],
      dialogs_confirm_change_role: [],
      users_except_current_count: 0,
    };
  },

  watch: {
    users_in_teams_grouped_and_filtered() {
      this.initConfirmationDialogs();
    },

    "$store.state.data_is_updated_toggle": function() {
      // reload the component's data
      this.loadUsersInTeams();
    },
  },

  mounted() {
    this.initConfirmationDialogs();
  },

  async created() {
    this.loadUsersInTeams();
  },

  computed: {
    ...mapState(["user_profile"]),
    ...mapGetters(["user_teams_in_which_he_is_admin"]),
  },

  methods: {
    ...mapActions(["toggleDataChange"]),

    initConfirmationDialogs() {
      this.dialogs_confirm_remove_user = [];

      if (null != this.users_in_teams_grouped_and_filtered) {
        for (let i = 0; i < this.users_except_current_count; i++) {
          this.dialogs_confirm_remove_user.push(false);
        }
      }

      this.dialogs_confirm_change_role = [];

      if (null != this.users_in_teams_grouped_and_filtered) {
        for (let i = 0; i < this.users_except_current_count; i++) {
          this.dialogs_confirm_change_role.push(false);
        }
      }
    },

    closeAllDialogs() {
      this.initConfirmationDialogs();
    },

    async loadUsersInTeams() {
      const users_in_teams_original = await getUsersInTeams();

      if (null != users_in_teams_original) {
        this.response_is_recieved = true;

        const users_in_teams_filtered = filterUsersInTeams(
          users_in_teams_original,
          this.user_profile
        );

        this.users_except_current_count = users_in_teams_filtered.length;

        this.users_in_teams_grouped_and_filtered = rearangeUsersByServicesAndTeams(
          users_in_teams_filtered
        );
      }
    },

    role_name(role) {
      switch (role) {
        case PREDEFINED_ROLES_ENUM.ADMIN:
          return this.$t("global.roles.admin");
        case PREDEFINED_ROLES_ENUM.OWNER:
          return this.$t("global.roles.owner");
        case PREDEFINED_ROLES_ENUM.USER:
          return this.$t("global.roles.user");
        default:
          return role;
      }
    },

    async deleteUserRole(item, i) {
      this.closeAllDialogs();

      await deleteUserFromRole(item.id);

      // notify the application about the database changes
      this.toggleDataChange();

      // update the current component data
      this.loadUsersInTeams();
    },

    async changeUserRole(item) {
      this.closeAllDialogs();

      const new_role = this.isAdminUser(item)
        ? PREDEFINED_ROLES_ENUM.USER
        : PREDEFINED_ROLES_ENUM.ADMIN;
      await changeUserRoleApi(item.id, new_role);

      // notify the application about the database changes
      this.toggleDataChange();

      // update the current component data
      this.loadUsersInTeams();
    },

    isAdminUser(item) {
      return PREDEFINED_ROLES_ENUM.ADMIN == item.role_name;
    },
  },
};
</script>
