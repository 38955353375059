<template>
  <span>
    <v-divider v-if="!footer_version" />
    <div
      :class="{
        'container mx-auto pa-0 pr-3 d-flex justify-end': !footer_version,
        'd-inline footer-language-selector': footer_version,
      }"
    >
      <div
        class="locale-changer"
        :class="{
          'd-inline': footer_version,
        }"
      >
        <country-flag :country="getCurrentCountryFlag" size="small" />
        <v-select
          class="min-content-width d-inline-block pl-2 mb-3 mt-1 pt-1"
          v-model="$i18n.locale"
          :items="langs"
          hide-details="true"
        >
          <template slot="item" slot-scope="data">
            <country-flag :country="data.item.country" size="small" />
            <span class="pl-2">{{ data.item.text }}</span>
          </template>
        </v-select>
      </div>
    </div>
  </span>
</template>

<script>
import CountryFlag from "vue-country-flag";
export default {
  components: { CountryFlag },
  data() {
    return {
      langs: [
        { text: "fr", country: "fr" },
        { text: "en", country: "gb" },
      ],
    };
  },

  props: {
    footer_version: { type: Boolean, default: false },
  },

  computed: {
    getCurrentCountryFlag() {
      for (const lang of this.langs) {
        if (lang.text == this.$i18n.locale) {
          return lang.country;
        }
      }
      // Should never reach here
      return "";
    },
  },
};
</script>
