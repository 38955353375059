<template>
  <v-main id="home">
    <language-changer v-if="!mobileAppWebView" />
    <router-view />
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageChanger from "../app_specific/LanguageChanger.vue";

export default {
  components: { LanguageChanger },
  name: "CoreView",

  created() {
    if (this.mobileAppWebView) {
      this.$i18n.locale = "en";
    }
  },

  computed: {
    ...mapGetters(["is_carnaval_service"]),

    mobileAppWebView() {
      const mobileAppWebViewByRouteName = "androidWebView" == this.$route.name;
      const mobileAppWebViewByQueryParam =
        null != this.$route.query &&
        null != this.$route.query.androidWebView &&
        "true" == this.$route.query.androidWebView;
      return (
        mobileAppWebViewByRouteName ||
        mobileAppWebViewByQueryParam ||
        this.is_carnaval_service
      );
    },
  },
};
</script>
