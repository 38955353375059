<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="!editing_mode_flag"
    class="disabled-text-black"
    :filled="!editing_mode_flag"
    :rounded="!editing_mode_flag"
    :outlined="editing_mode_flag"
    :class="{ 'rounded-lg': !editing_mode_flag }"
    color="dark"
    hide-details="auto"
    clearable
    @click:clear="$emit('clear')"
  />
</template>

<script>
export default {
  props: {
    editing_mode_flag: false,
  },
};
</script>
